import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import axios from "axios";
import loader from "../../assets/images/giphy.gif";

function TimeTable() {
  const user_id = localStorage.getItem("user_id");
  const school_id = localStorage.getItem("school_id");
  const class_id = localStorage.getItem("class_id");
  const token = localStorage.getItem("token");
  const daysOfWeek = ["MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const [activeDay, setActiveDay] = useState(null);
  const [classtime, setClassTime] = useState([]);
  const isLoading = useRef(false);
  const [loading, setLoading] = useState(true);

  const handleDayClick = (day) => {
    if (day === getCurrentDay()) {
      setActiveDay(day);
    }
  };

  const getCurrentDay = () => {
    const today = new Date();
    const options = { weekday: "short" };
    return new Intl.DateTimeFormat("en-US", options)
      .format(today)
      .toUpperCase();
  };

  const convertTime = (timeStr) => {
    let [hours, minutes] = timeStr.split(":");
    hours = parseInt(hours, 10);
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        isLoading.current = true;
        const url = `${process.env.REACT_APP_API_URL}school-teacher-timetable`;
        const formData = new FormData();
        formData.append("school_id", school_id);
        formData.append("class_id", class_id);

        const config = {
          method: "POST",
          url: url,
          data: formData,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        };

        const response = await axios(config);
        setClassTime(response.data.data);
        setLoading(false);
        const currentDate = new Date();
        const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        const currentDay = daysOfWeek[currentDate.getDay()];
        setActiveDay(currentDay);
        isLoading.current = false;
      } catch (error) {
        isLoading.current = false;
        console.error("Error fetching user data:", error);
        setLoading(false);
      }
    };
    if (!isLoading.current) {
      fetchData();
    }
  }, [token, class_id, school_id]);

  return (
    <div className="App profile fee Assignment Timetable">
      <div className="bg_color_heading padding-bottom-60">
        <div className="heading_content">
          <div className="heading_right">
            <Link to="/Dashboard">
              <IoIosArrowBack className="fs-2 text-light" />
            </Link>
            <p className="text-light ms-1">Time table</p>
          </div>
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col md={12} sm={12}>
            <div className="round_bg margin-top-minus-70">
              <Container>
                {loading ? (
                  <div className="img-fluidloder">
                    <img src={loader} alt="quiz" />
                  </div>
                ) : (
                  <Row>
                    <div>
                      <div className="days">
                        {daysOfWeek.map((day) => (
                          <div
                            key={day}
                            className={`single_day ${
                              activeDay === day ? "active_day" : ""
                            }`}
                            onClick={() => handleDayClick(day)}
                          >
                            <span>{day}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <Col md={12} sm={12}>
                      <div className="fee_card">
                        <Table
                          className="mb-0 backgroundtablecoolor"
                          striped
                          bordered
                          hover
                        >
                          <thead>
                            <tr>
                              <th className="text-center">Subject </th>
                              <th className="text-center">Teacher</th>
                              <th className="text-center">Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {classtime && classtime.length > 0 ? (
                              classtime.map((user, index) => (
                                <tr key={index}>
                                  <td className="text-capitalize text-center">
                                    {user.subject_name}
                                  </td>
                                  <td className="text-capitalize text-center">
                                    {user.teacher_name}
                                  </td>
                                  <td className="text-center">
                                    {convertTime(user.from_time)} -{" "}
                                    {convertTime(user.to_time)}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan="3"
                                  className="text-center nodataavl Nodata"
                                >
                                  No data available or something went wrong.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                )}
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TimeTable;
