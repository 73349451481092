import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Container, Card, Modal, Button, Spinner } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import { LuClock3 } from "react-icons/lu";
import { FaUsers } from "react-icons/fa";
import { BsCheckCircleFill, BsDisplay } from "react-icons/bs";
import Swal from "sweetalert2";
import axios from "axios";
import loader from "../../assets/images/giphy.gif";
import { useNavigate } from "react-router-dom";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import { AiOutlineDoubleRight } from "react-icons/ai";
import ProgressBar from 'react-bootstrap/ProgressBar';

const url = new URL(window.location.href);
const chapter1 = url.searchParams.get("id");

const url1 = new URL(window.location.href);
const course = url1.searchParams.get("course");

function Quiz() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [showModal, setShowModal] = useState(true);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [nextButtonClicked, setNextButtonClicked] = useState(false);
  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);

  const handleClose = () => {
    setShowModal(false);
  };

  const user_id = localStorage.getItem("user_id");
  const coursid = localStorage.getItem("coursid");
  const sub_id = localStorage.getItem("subid");
  // alert(sub_id);
  const chapter_id = localStorage.getItem("chapter_id");
  const model_id = localStorage.getItem("model_id");
  const models_id = localStorage.getItem("models_id");
  const school_id = localStorage.getItem("school_id");
  const time = localStorage.getItem("time");
  const totalmarks = localStorage.getItem("totalmarks");
  const token = localStorage.getItem("token");
  const questionsLength = localStorage.getItem("noq");
  const group_id = localStorage.getItem("group_id");
  const subcategory_id = localStorage.getItem("subcategory_id");
  // const group_idss = localStorage.getItem("group_idss");



  const [questions, setStudentQuestions] = useState([]);
  const [questions1, setStudentNoQuestions] = useState([]);
  const [applymessage, setApplymessage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [answers1, setSubmitAns] = useState("");
  const [answers, setAnswers] = useState(Array(questions && questions.length).fill());
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const isLoading = useRef(false);

  useEffect(() => {
    if(!isLoading.current){
   loadUser();
    }
  }, []);

const submitAnswers = async (event) => {
    try {
     
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}submit-question`;
      const d = {
        user_id: user_id,
        modal_id: model_id,
        category_id: coursid,
        chapter_id: chapter_id,
        subcategory_id: sub_id || subcategory_id,
        school_id: school_id,
        totalmarks: totalmarks,
        questionsLength: questionsLength,
        group_id: group_id,
        models_id: models_id,
        answerDt: JSON.stringify({ answer_data: answers }),
      };
  
      const config = {
        method: "post",
        url: url,
        data: d,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      const data = response.data.data;
      isLoading.current = false;
      console.warn(data);
      Swal.fire({
        icon: "success",
        title: "Submission Successful!",
        text: "Your answers have been submitted successfully.",
        timer: 2000,
      }).then(() => {
        localStorage.setItem("questionsLength", questionsLength);
        localStorage.setItem("group_id", group_id);
        navigate("/Result");
      });
    } catch (error) {
      isLoading.current = false;
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setLoading(false);
      setSubmitButtonDisabled(false);
    }
  };


  const loadUser = async () => {
    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}get-question`;
      // console.log(time, "time......");
      const formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("category_id", coursid);
      formData.append("model_id", model_id);
      formData.append("models_id", models_id);
      formData.append("subcategory_id", sub_id);
      formData.append("chapter_id", chapter_id);
      formData.append("school_id", school_id);
      formData.append("time", time);
      formData.append("totalmarks", totalmarks);
      formData.append("group_id", group_id);
      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      setApplymessage(response.data.status_code);
      const demo = response.data.data;
      const demo1 = response.data.message;
      setStudentQuestions(demo);
      setStudentNoQuestions(demo1);
      // alert(demo1);
      isLoading.current = false;
      const noQ = response.data.data.length;
      localStorage.setItem("noq", noQ)
      
    } catch (error) {
      isLoading.current = false;
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const onSubmit = (id) => {
    submitAnswers();
    localStorage.setItem("answerDt", id);
    window.location.href = `/Result`;
  };

  // const handleOptionClick = (selectedOption) => {
  //   const updatedAnswers = [...answers];
  //   updatedAnswers[currentQuestionIndex] = selectedOption;
  //   setAnswers(updatedAnswers);
  //   localStorage.setItem("answers", JSON.stringify(updatedAnswers));
  //   setSelectedOption(selectedOption);
  // };

  const handleOptionClick = (selectedOption) => {
    let updatedAnswers = [...answers];
    let newSelectedOption = selectedOption;
  
    // Check if the selected option is already the current selection
    if (
      selectedOption &&
      selectedOption.question_id === answers[currentQuestionIndex]?.question_id &&
      selectedOption.answer_id === answers[currentQuestionIndex]?.answer_id
    ) {
      // If so, deselect it
      newSelectedOption = null;
      updatedAnswers[currentQuestionIndex] = null;
    } else {
      // Otherwise, select the new option
      updatedAnswers[currentQuestionIndex] = selectedOption;
    }
  
    setAnswers(updatedAnswers);
    localStorage.setItem("answers", JSON.stringify(updatedAnswers));
    setSelectedOption(newSelectedOption);
  };
  

  
  const handlePrevClick = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
      setNextButtonClicked(true);
      setIsLastQuestion(false);
      if (currentQuestionIndex === questions.length - 1) {
        setIsLastQuestion(false);
      }
    }
  };

  const handleNextClick = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setNextButtonClicked(true);
      const storedQuestionId = localStorage.getItem("question_id");
      localStorage.setItem("question_id", questions[currentQuestionIndex].id);

      if (currentQuestionIndex === questions.length - 2) {
        setIsLastQuestion(true);
      }
    }
  };

  // const handleSkipClick = () => {
  //   if (currentQuestionIndex < questions.length - 1) {
  //     setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  //     setNextButtonClicked(true);
  //     if (currentQuestionIndex === questions.length - 2) {
  //       setIsLastQuestion(true);
  //     }
  //     const storedQuestionId = localStorage.getItem("question_id");
  //     localStorage.setItem("question_id", questions[currentQuestionIndex].id);
  //   }
  // };


  const handleSkipClick = () => {
    if (currentQuestionIndex < questions.length - 1) {
      const storedQuestionAnswers = JSON.parse(localStorage.getItem("answers")) || [];
      while (storedQuestionAnswers.length < questions.length) {
        storedQuestionAnswers.push(0);
      }
      const updatedAnswers = storedQuestionAnswers.map((answer, index) => {
        if (index === currentQuestionIndex) {
          return {"question_id": questions[index].id, "answer_id": 0};
        } else {
          return {"question_id": questions[index].id, "answer_id": answer};
        }
      });
      localStorage.setItem("answers", JSON.stringify(updatedAnswers));
  
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setNextButtonClicked(true);
  
      if (currentQuestionIndex === questions.length - 2) {
        setIsLastQuestion(true);
      }
    }
  };
  
  useEffect(() => {
    const storedAnswers = JSON.parse(localStorage.getItem("answers"));
    if (storedAnswers && storedAnswers.length === questions && questions.length) {
      setAnswers(storedAnswers);
    }
    const percentage = ((currentQuestionIndex + 1) / (questions && questions.length)) * 100;
    setProgressPercentage(percentage);
  }, [currentQuestionIndex, questions && questions.length]);

  return (
        <div className="App profile Quiz">
           {loading ? (
        <div className="img-fluidloder">
          <img src={loader} alt="quiz" />
        </div>
      ) : (
        applymessage === 3 ? (
          
          <tr>
            <div className="heading_right pt-3">
            <Link to="/Dashboard">
              <IoIosArrowBack className="fs-2 text-light" />
            </Link>
            <p className="text-light ms-1">Dashboard</p>
          </div>
            <td colSpan="7" className="text-center nodataavl12 Nodata">
              <h5 className="already">{questions1}</h5>
            </td>
          </tr>
        ) : (
            <>
      <Modal show={showModal} onHide={handleClose} className="instruction">
        <Modal.Header>
          <Modal.Title className="fs-5">Quiz instructions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ol className="mb-0">
            <h5><b> Please read instructions carefully before start exam.</b></h5>
          <li>Total number of questions: {questions && questions.length}</li>
            <li>Time allotted: {time} minutes</li>
            <li>Total Marks: {totalmarks}</li>
            <li>Read each question carefully before answering.</li>
            <li>Ensure that you have a stable internet connection.</li>
            <li>Keep only the quiz tab open to avoid any potential issues.</li>
            <li>Complete the quiz on your own without external help.</li>
            <li>Understand the format of questions (multiple-choice, true/false, short answer, etc.).</li>
            <li>Contact the quiz organizer if you encounter technical issues.</li>
          </ol>
        
          <h5 className="mt-3 text-center"><b>Enjoy the quiz, and Do your best!</b></h5>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Container>
        <Row className="heading_content">
          <Col md={6} sm={6} xs={6}>
            <div className="heading_right">
              <Link to="/QuizModel">
                <IoIosArrowBack className="fs-2 text-light" />
              </Link>
              <p className="text-light ms-1">Play Quiz</p>
            </div>
          </Col>
          <Col md={6} sm={6} xs={6}>
            {/* <p className="skip">Skip</p> */}
          </Col>
        </Row>

        <Row>
          <div>
  <div className="timer">
    {/* <div className="remaining_time">
      <span>18 Sec</span>
    </div> */}
     <ProgressBar animated now={progressPercentage} />
  </div>   

            <div className="question">
              <div className="number">
                {questions && questions.length > 0 && (
                  <p>{`Question ${currentQuestionIndex + 1}/${
                    questions.length
                  }`}</p>
                )}
              </div>
            </div>
          </div>
          <Col md={12} sm={12}>
            <div>
              <Container>
                <Row>
                  {questions && questions.length > 0 ? (
                    <Card className="quiz_box">
                      <div className="">
                        <Row>
                        <h4>{questions[currentQuestionIndex].title}</h4> 
                       <h4 className="marks d-flex justify-content-end"> marks -{questions[currentQuestionIndex].marks}</h4> 
                        </Row>
                        {questions[currentQuestionIndex].answer.map(
                          (option, optionIndex) => (
                            <div
                              key={optionIndex}
                              className={`option ${
                                selectedOption &&
                                selectedOption.question_id ===
                                  option.question_id &&
                                selectedOption.answer_id === option.id
                                  ? "right_option"
                                  : ""
                              }`}
                              onClick={() =>
                                handleOptionClick({
                                  question_id: option.question_id,
                                  answer_id: option.id,
                                })
                              }
                            >
                              <p>{option.answer}</p>
                              <span>
                                {selectedOption &&
                                  selectedOption.question_id ===
                                    option.question_id &&
                                  selectedOption.answer_id === option.id && (
                                    <BsCheckCircleFill className="fs-4" />
                                  )}
                              </span>
                            </div>
                          )
                        )}
                      </div>
                      {isLastQuestion || (questions.length === 1 && currentQuestionIndex === 0) ? (
                        <Button
                          onClick={() => submitAnswers(answers)}
                          disabled={loading}
                          size="lg"
                          className="mt-5 mb-5 mybutton w-100"
                        >
                          {loading && <Spinner animation="border" />} 
                          Submit
                        </Button>
                      ) : (
                        // <Row>
                        //   <Col md="12" sm="12" xs="12">
                        <div className="buttons_three">
                          <Button className="next" onClick={handleNextClick}>
                            Next <AiOutlineDoubleRight className="chivpre" />
                          </Button>
                        </div>
                        //   </Col>
                        // </Row>
                      )}
                      <Row>
                        <Col md="12" sm="12" xs="12">
                          <div className="buttons_three">
                            {nextButtonClicked && currentQuestionIndex > 0 && (
                              <Button
                                className="prev"
                                onClick={handlePrevClick}
                              >
                                <AiOutlineDoubleLeft className="chivpre" />
                                Prev
                              </Button>
                            )}
                            
                            {!isLastQuestion && (
                              <Button className="skip" onClick={handleSkipClick}>
                                Skip <AiOutlineDoubleRight className="chivpre" />
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center nodataavl Nodata">
                       No Data Available or somthing went wrong.
                      </td>
                    </tr>
                  )}
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
      </>
        )
      )}
  
</div>
      
);
}

export default Quiz;
