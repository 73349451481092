import React from 'react';

const LanguageDropdown = ({ onChange }) => {
    return (
        <select className="languageselect" onChange={onChange} defaultValue="en">
            <option value="en">English</option>
            <option value="hi">हिन्दी</option>
        </select>
    );
};

export default LanguageDropdown;
