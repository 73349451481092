// import React, { useState, useEffect, useRef } from "react";
// import { Row, Col, Container, Card } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { IoIosArrowBack } from "react-icons/io";
// import loader from "../../assets/images/giphy.gif";
// import axios from "axios";

// function Notification() {
//   const school_id = localStorage.getItem("school_id");
//   const student_id = localStorage.getItem("user_id");
//   const class_id = localStorage.getItem("class_id");
//   const token = localStorage.getItem("token");
//   const [notification, setNotification] = useState([]);
//   const [studentMsg, setStudentMsg] = useState([]);
//   const isLoading = useRef(false);
//   const [loading, setLoading] = useState(false);
//   const [badgeCount, setBadgeCount] = useState(0);

//   const loadTeachers = async () => {
//     try {
//       isLoading.current = true;
//       setLoading(true);
//       const url = `${process.env.REACT_APP_API_URL}student-leave-notification`;
//       const formData = new FormData();
//       formData.append("student_id", student_id);
//       formData.append("school_id", school_id);
//       formData.append("class_id", class_id);
//       const config = {
//         method: "post",
//         url: url,
//         data: formData,
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "multipart/form-data",
//         },
//       };
//       const response = await axios(config);
//       const teachersData = response.data;
//       const teacherApprive = teachersData.notification;
//       const studentMsg = response.data.detsils;
//       setNotification(teacherApprive);
//       setStudentMsg(studentMsg);
//       const stdnotificationCount = response.data.count;
//       localStorage.setItem("stdnotificationCount", stdnotificationCount);
//       isLoading.current = false;
//       setLoading(false);
//     } catch (error) {
//       isLoading.current = false;
//       setLoading(false);
//       console.error("There was a problem with the fetch operation:", error);
//     }
//   };

//   useEffect(() => {
//     if (!isLoading.current) {
//       loadTeachers();
//       const stdnotificationCount = localStorage.getItem("stdnotificationCount");
//       setBadgeCount(stdnotificationCount || 0);
//       localStorage.setItem("stdnotificationCount", 0); // Reset badge count to 0
//     }     
  
//   }, []);

//   return (
//     <div className="App profile fee Assignment">
//       <Container>
//         <Row className="heading_content">
//           <Col md={6} sm={6} xs={6}>
//             <div className="heading_right">
//               <Link to="/Dashboard"  >
//                 <IoIosArrowBack className="fs-2 text-light" />
//               </Link>
//               <p className="text-light ms-1">Notification </p>
//             </div>
//           </Col>
//         </Row>

//         {loading ? (
//           <div className="img-fluidloder">
//             <img src={loader} alt="quiz" />
//           </div>
//         ) : (
//           <>
//             <Row>
//               <Col lg={12} md={12} sm={12} className="pb-5 heading_content">
//                 <div className="" id="notificationstd">
//                   {studentMsg && studentMsg.length > 0 ? (
//                     studentMsg.map((stdmsg, index) => (
//                       <Card className="holiday_list mb-2 bgimagefortop">
//                         <Card.Body className="p-0 Nborder">
//                           <Card.Text className="bg-image-new">
//                             <div className="meetingtime position-relative">
//                               <h1 className="titlecom pt-5">{notification}</h1>
//                               <p className="leave">{stdmsg && stdmsg.message}</p>
//                               <div className="card_time mt-0">
//                                 <div className="d-flex justify-content-between py-2">
//                                   <div class="stdmsg mt-5">
//                                     <Col 
//                                       key={stdmsg && stdmsg.message}
//                                       className=""
//                                       lg={12}
//                                       xs={12}
//                                     >
//                                       {/* <p className="">{stdmsg && stdmsg.message}</p> */}
//                                     </Col>
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>
//                           </Card.Text>
//                         </Card.Body>
//                       </Card>
//                     ))
//                   ) : (
//                     <tr>
//                       <td
//                         colSpan="7"
//                         className="text-center nodataavl Nodata1"
//                       >
//                         No data available or something went wrong.
//                       </td>
//                     </tr>
//                   )}
//                 </div>
//               </Col>
//             </Row>
//           </>
//         )}
//       </Container>
//     </div>
//   );
// }

// export default Notification;



 import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import loader from "../../assets/images/giphy.gif";
import axios from "axios";

function Notification() {
  const school_id = localStorage.getItem("school_id");
  const student_id = localStorage.getItem("user_id");
  const class_id = localStorage.getItem("class_id");
  const token = localStorage.getItem("token");
  const [notification, setNotification] = useState([]);
  const [studentMsg, setStudentMsg] = useState([]);
  const [count, setCount] = useState(0);
  const isLoading = useRef(false);
  const [loading, setLoading] = useState(false);

  const loadTeachers = async () => {
    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}student-leave-notification`;
      const formData = new FormData();
      formData.append("student_id", student_id);
      formData.append("school_id", school_id);
      formData.append("class_id", class_id);
      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios(config);
      const teachersData = response.data;
      const teacherApprive = teachersData.notification;
      const studentMsg = response.data.detsils;
      setNotification(teacherApprive);
      setStudentMsg(studentMsg);
      const stdnotificationCount = response.data.count;
      localStorage.setItem("stdnotificationCount", stdnotificationCount);
      isLoading.current = false;
      setLoading(false);
    } catch (error) {
      isLoading.current = false;
      setLoading(false);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  useEffect(() => {
    if (!isLoading.current) {
      loadTeachers();
      setCount(0);
    }     
  
  }, []);

  return (
    <div className="App profile fee Assignment">
      <Container>
        <Row className="heading_content">
          <Col md={6} sm={6} xs={6}>
            <div className="heading_right">
              <Link to="/Dashboard"  >
                <IoIosArrowBack className="fs-2 text-light" />
              </Link>
              <p className="text-light ms-1">Notification </p>
            </div>
          </Col>
        </Row>

        {loading ? (
          <div className="img-fluidloder">
            <img src={loader} alt="quiz" />
          </div>
        ) : (
          <>
            <Row>
              <Col lg={12} md={12} sm={12} className="pb-5 heading_content">
                <div className="" id="notificationstd">
                  {studentMsg && studentMsg.length > 0 ? (
                    studentMsg.map((stdmsg, index) => (
                      <Card className="holiday_list mb-2 bgimagefortop">
                        <Card.Body className="p-0 Nborder">
                          <Card.Text className="bg-image-new">
                            <div className="meetingtime position-relative">
                              <h1 className="titlecom pt-5">{notification}</h1>
                              <p className="leave">{stdmsg && stdmsg.message}</p>
                              <div className="card_time mt-0">
                                <div className="d-flex justify-content-between py-2">
                                  <div class="stdmsg mt-5">
                                    <Col 
                                      key={stdmsg && stdmsg.message}
                                      className=""
                                      lg={12}
                                      xs={12}
                                    >
                                      {/* <p className="">{stdmsg && stdmsg.message}</p> */}
                                    </Col>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="7"
                        className="text-center nodataavl Nodata1"
                      >
                        No data available or something went wrong.
                      </td>
                    </tr>
                  )}
                </div>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </div>
  );
}

export default Notification;