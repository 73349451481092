import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { toast } from "react-toastify";
import user from "../../assets/images/not found.jpg";
import send from "../../assets/svg/icons8-send-message-48.png";
import loader from "../../assets/images/giphy.gif";

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [studentChat, setStudentChat] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [lastDay, setLastDay] = useState("");
  const [teacherSentMessage, setTeacherSentMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const school_id = localStorage.getItem("school_id");
  const studentId = localStorage.getItem("user_id");
  const classId = localStorage.getItem("class_id");
  const teacher_id = localStorage.getItem("teacher_id");
  const teacherName = localStorage.getItem("teacher_name");
  const token = localStorage.getItem("token");

  const isLoading = useRef(false);
  const intervalRef = useRef(null);

  // Function to handle sending a message
  const handleClicksend = async () => {
    if (!isButtonClicked && newMessage.trim() !== "") {
      setIsButtonClicked(true);
      try {
        await fetchChats();
        setNewMessage("");
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
          clearInterval(intervalRef.current);
          setIsButtonClicked(false);
        }, 3000);
      } catch (error) {
        console.error("Error sending message:", error);
        setIsButtonClicked(false);
      }
    }
  };

  // Function to fetch chat messages
  const fetchChats = async () => {
    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}student-teacher-chat`;
      const formData = new FormData();
      formData.append("school_id", school_id);
      formData.append("teacher_id", teacher_id);
      formData.append("student_id", studentId);
      formData.append("class_id", classId);
      formData.append("message", newMessage);

      const config = {
        method: "POST",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      setMessages(response.data.data);
      setNewMessage("");
      if (response.data.status === true) {
        toast.success(response.data.message, {
          onClose: () => {
            setTimeout(() => {
              // loaduser();
            }, 1000);
          },
        });
      } else {
        toast.error(response.data.message);
      }

      isLoading.current = false;
      setLoading(false);
    } catch (error) {
      isLoading.current = false;
      setLoading(false);
      console.error("Error fetching user data:", error);
    }
  };

  // Function to fetch chat list
  const fetchChatList = async () => {
    try {
      isLoading.current = true;
      setLoading(true);

      const url = `${process.env.REACT_APP_API_URL}student-teacher-chat-list`;
      const formData = new FormData();
      formData.append("school_id", school_id);
      formData.append("teacher_id", teacher_id);
      formData.append("student_id", studentId);
      formData.append("class_id", classId);

      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);

      setStudentChat(response.data.message_lists);
      setCurrentDate(response.data.currentdate);
      setLastDay(response.data.YesterdayDay);

      isLoading.current = false;
      setLoading(false);
    } catch (error) {
      isLoading.current = false;
      setLoading(false);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isLoading.current) {
        fetchChatList();
      }
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  const divRef = useRef(null);
  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, []);

  useEffect(() => {
    const teacherHasSentMessage = studentChat.some(
      (message) => message.sender_type === "teacher"
    );
    setTeacherSentMessage(teacherHasSentMessage);
  }, [studentChat]);

  return (
    <div className="wp_back">
      <div className="chat-contain">
        <div className="chat_header">
          <Link to="/Dashboard">
            <IoIosArrowBack className="fs-2 text-light" />
          </Link>
          <img src={user} alt="user" className="user" />
          <div className="username">
            <span className="text-capitalize">{teacherName}</span>
          </div>
        </div>
        <div className="chat-messages">
          {studentChat.length === 0 ? (
            <p className="text-center mt-3">Start a conversation...</p>
          ) : (
            <ul className="chats ps-0" ref={divRef} style={{ overflowY: "scroll" }}>
              {studentChat.map((message, index) => {
                const messageDate =
                  message.sender_type === "teacher"
                    ? message.teacher_chat_date
                    : message.student_chat_date;
                
                const isFirstMessageOfDay =
                  index === 0 ||
                  messageDate !==
                    (
                      studentChat[index - 1].sender_type === "teacher"
                        ? studentChat[index - 1].teacher_chat_date
                        : studentChat[index - 1].student_chat_date
                    );

                return (
                  <React.Fragment key={index}>
                    {isFirstMessageOfDay && (
                      <p className="d-flex justify-content-center chat_date divider">
                        {messageDate}
                      </p>
                    )}
                    {message.sender_type === "student" ? (
                      <li className="chat" key={index}>
                        <div>
                          <span>{message.message}</span>
                          <p className="time">{message.student_chat_time}</p>
                        </div>
                      </li>
                    ) : (
                      <li className="chat1" key={index}>
                        <div className="chattt">
                          <span>{message.message}</span>
                          <p className="time">{message.teacher_chat_time}</p>
                        </div>
                      </li>
                    )}
                  </React.Fragment>
                );
              })}
            </ul>
          )}
        </div>
        <div className="chatbox-holder">
          <textarea
            rows="4"
            cols="50"
            className="chat-input"
            type="text"
            placeholder="Type your message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
          />
          <img
            className="send"
            src={send}
            onClick={handleClicksend}
            alt="Send"
            disabled={isButtonClicked || newMessage.trim() === ""}
          />
        </div>
      </div>
    </div>
  );
};

export default Chat;
