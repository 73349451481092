import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from "../../assets/images/logo.png";
import Splash2 from "../../assets/images/splash.png";
import loader from '../../assets/images/giphy.gif';
import { Link,useLocation } from "react-router-dom";
import { Row, Col, Container, Form, Button, Spinner } from "react-bootstrap";
import Splash from "../../assets/images/login.png";
import { ArrowRight, EyeSlash, EyeFill } from "react-bootstrap-icons";

function Login() {
  alert("fgjfh")
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  const [showComponent, setShowComponent] = useState(true);
  const [hideComponent, sethideComponent] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowComponent(false);
      sethideComponent(true);
    }, 2000);
  }, []);
  
  const [showPassword, setShowPassword] = useState(false);
  const [name, setname] = useState("");
  const [password, setPassword] = useState("")
  const [browserId, setBrowserId] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState("");

  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const onInputChangename = (e) => {
    setname(e.target.value);
  };

  const onInputChangepassword = (e) => {
    setPassword(e.target.value);
  };

  const Submit = async () => {
    try {
      if (!name) {
        toast.error('Please enter a valid Student Email.');
        return;
      }
      if (!password || password.length < 6) {
        toast.error('Password must be at least 6 characters long.');
        return;
      }

      setLoading(true); 

      let url = (`${process.env.REACT_APP_API_URL}StudentUserlogin`);
      const formData = new FormData();
      formData.append('email', name);
      formData.append('password', password);
      const config = {
        method: 'POST',
        url: url,
        data: formData,
      };

      axios(config)
        .then(function (response) {
          
          if (response.data.status === 0) {
            const message = response.data.message;
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: message,
              timer: 2000
            })
          }
          else if (response.data.status === 1) {
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Login successfully.',
              timer: 2000
            }).then((result) => {
              // navigate('/dashboard');
              localStorage.setItem("user_id", response.data.data.id);
              localStorage.setItem('school_id', response.data.data.school_id);
              localStorage.setItem('token', response.data.bear_token);
              localStorage.setItem('multi_device_session', response.data.multi_device_session);
              // window.localStorage.setItem('isLoggedIn', true);
              // localStorage.setItem("isLoggedIn", "true");
              // setLoggedIn(true);
              // Redirect to dashboard or any other authenticated route
              navigate("/dashboard");
            })
          } else {
            toast.error(response.data.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setLoading(false); 
    }
  }

  return (
    <>
    <div>
        {showComponent && (
          <div className="App">
            <Container>
              <div className="App-header text-center py-5">
                <Link to="/Login">
                  <img src={logo} className="App-logo" alt="logo" />
                </Link>
              </div>
              <Row className="text-center mt-5 pb-5">
                <Col md={12}>
                  <img src={Splash2} alt="Splash" className="img-fluid" />
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>
      {hideComponent && (
    <div className="App">
      <Container>
        <Row className="text-center pt-5 pb-5 hero">
          <Col md={12} sm={12}>
            <img src={Splash} alt="Splash" className="img-fluid" />
          </Col>
        </Row>
        <Row className="pt-5 pb-5 position-relative">
          <Col md={12} sm={12}>
            <div className="Login-form py-5">
              <h3 className="fs-34">Welcome Student</h3>
              {/* <p className="fs-20">Sign in to continue</p> */}
              <Form>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                  <Form.Label column sm="2"> Student Email</Form.Label>
                  <Col sm="10">
                    <Form.Control type="text" required value={name} onChange={onInputChangename} placeholder="Student Email"
                      className="border-0 border-bottom"/>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="2">
                    Password
                  </Form.Label>
                  <Col sm="10" className="position-relative">
                  <Form.Control
                       type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      className="border-0 border-bottom"
                      value={password}
                  onChange={onInputChangepassword}
                  required
                 
                    />
                    {showPassword ? (
                    <EyeSlash
                      className="fs-3 eye"
                      onClick={togglePasswordVisibility}
                    />
                  ) : (
                    <EyeFill
                      className="fs-3 eye"
                      onClick={togglePasswordVisibility}
                    />
                  )}
                  </Col>
                </Form.Group>
               
                  <Button size="lg" className="mybutton1 w-100 d-flex justify-content-around" onClick={Submit} disabled={loading}> 
                    SIGN IN
                    {loading && <Spinner animation="border" />}
                    <ArrowRight className="fs-3 fw-bold" />
                  </Button>
              </Form>
             
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />

    </div>
     )}
    </>
  );
}

export default Login;
