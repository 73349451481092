import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import loader from "../../assets/images/giphy.gif";
import axios from "axios";
import chat from '../../assets/svg/icons8-chat-48.png';
import { useNavigate } from "react-router-dom";
import teacherimg from '../../assets/images/teacherimg.jpg';

function Faculty_profile() {
  const school_id = localStorage.getItem("school_id");
  const token = localStorage.getItem("token");
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const isLoading = useRef(false);
  const [image, setImage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading.current) {
      loadTeachers();
    }
  }, []);

  const loadTeachers = async () => {
    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}teacher-lists`;
      const formData = new FormData();
      formData.append("school_id", school_id);
      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      const completeImageUrl = response.data.url;
      const teachersData = response.data.data;

      setImage(completeImageUrl);
      setTeachers(teachersData);
      isLoading.current = false;
    } catch (error) {
      isLoading.current = false;
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleClick = (teacher_id, teacher_name) => {
    localStorage.setItem("teacher_id", teacher_id);
    localStorage.setItem("teacher_name", teacher_name);
    navigate('/Chat');
  };

  return (
    <div className="App profile fee Assignment feculty">
            <div className="bg_color_heading padding-bottom-80">

      <div className="heading_content">
          <div className="heading_right">
            <Link to="/Dashboard">
              <IoIosArrowBack className="fs-2 text-light" />
            </Link>
            <p className="text-light ms-1">Faculty Profile</p>
          </div>
          </div>
      </div>
      {loading ? (
        <div className="img-fluidloder">
          <img src={loader} alt="Loading..." />
        </div>
      ) : (
        <Col lg={12} md={12} sm={12} className="pb-5 heading_content">
          <div className="round_bg margin-top-minus-60">
            <Container className="">
              {teachers && teachers.length > 0 ? (
                teachers && teachers.map((teacher) => (
                  <Col key={teacher.teacher_id} className="my-2" lg={12} xs={12}>
                    <Card className="Teacher_Profile">
                      <div className="profile_2">
                        <div className="d-flex align-items-center">
                          <div className="teacherImg">
                            {/* <img
                              src={teacher.profile_image ? `${image}/${teacher.profile_image}` : teacherimg}
                              alt={teacher.teacher_name}
                            /> */}
                            <img
  src={teacher.profile_image ? `${image}/${teacher.profile_image}` : teacherimg}
  alt={teacher.teacher_name || 'Profile Image'}
/>
                          </div>
                          <div className="Fprofile">
                            <p className="UserName1 text-capitalize">{teacher.teacher_name}</p>
                            <div>
                              <ul className="dd">
                                {teacher.subjects.map((subject) => (
                                  <li className="sub_list  text-capitalize" key={subject.subject_id}>
                                    {subject.subject_name}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <Link
                          onClick={() =>
                            handleClick(
                              teacher.teacher_id,
                              teacher.teacher_name
                            )
                          }
                        >
                          <div className="d-flex justify-content-end">
                            <Link to="/Chat">
                            <span className="btn_teacher">
                              {/* <img
                                className="d-flex justify-content-end"
                                src={chat}
                                alt="Chat"
                              /> */}
                              Chat With Teacher
                            </span>
                            </Link>
                          </div>
                        </Link>
                      </div>
                    </Card>
                  </Col>
                ))
              ) : (
                <p className="text-center">No teachers available</p>
              )}
            </Container>
          </div>
        </Col>
      )}
    </div>
  );
}

export default Faculty_profile;
