import { React, useEffect, useState, useRef } from "react";
import { Row, Col, Container, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { FaCheck, FaLock } from "react-icons/fa6";
import { CiCamera } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import loader from '../../assets/images/giphy.gif';
import FadeLoader from "react-spinners/FadeLoader";

function Profile() {
  const navigate = useNavigate();
  const user_id = localStorage.getItem("user_id");
  const school_id = localStorage.getItem("school_id");
  const token = localStorage.getItem("token");
  console.log(token);
  const [getuserdata, setdata] = useState([]);
  const [getstudentdata, setStudentData] = useState([]);
  const [getclassData, setClassData] = useState([]);
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(true);
  const isLoading = useRef(false);

  useEffect(() => {
    if (!isLoading.current) {
      loadUser();
    }
  }, []);

  const loadUser = async () => {
    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}getStudentProfile`;

      const formData = new FormData();
      formData.append("id", user_id);
      formData.append("school_id", school_id);

      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      setLoading(true);
      const response = await axios(config);
      setdata(response.data.data);

      const setstudentData = response.data.studentData;
      setStudentData(setstudentData);
      setClassData(response.data.studentData.class_details);

      const completeImageUrl = `${response.data.url}/${response.data.studentData.profile_image}`;
      setImage(completeImageUrl);
      isLoading.current = false;
    } catch (error) {
      isLoading.current = false;
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  return (
    <div className="App profile">
            <div className="bg_color_heading padding-bottom-80">

        <div className="heading_content">
            <div className="heading_right">
              <Link to="/dashboard">
                <IoIosArrowBack className="fs-2 text-light" />
              </Link>
              <p className="text-light ms-1">My Profile</p>
            </div>
          
        </div>
        </div>
      <Container fluid>

        {loading ? (
          <div className="img-fluidloder">
            <img
              src={loader}
              alt="quiz"

            />
          </div>
        ) : (
        <Row>
          <Col md={12} sm={12}>
            <div className="round_bg">
              <Container>
                <Row>
                  <Col md={12} sm={12}>
                    <Card className="stu_profile">
                      <div className="d-flex">
                        <div className="dp">
                          <img src={image} alt="Student" />
                        </div>
                        <div className="profile_detail">
                          <h3 className="UserName">{getstudentdata && getstudentdata.name}</h3>
                          <p>
                            Class -
                            {getclassData && getclassData.name}
                          </p>
                        </div>
                      </div>
                      <div></div>
                    </Card>
                    {/* )} */}
                  </Col>

                  <Col md={12} xs={12}>
                    <Form className="profile_form">
                      <Row>
                        <Col md={6} xs={6}>
                          <Form.Group className="mb-3">
                            <Form.Label column sm="2">
                              Admission Type
                            </Form.Label>
                            <Col sm="12" className="position-relative">
                              <Form.Control
                                type="text"
                                placeholder={
                                  getstudentdata &&
                                  getstudentdata.admission_type
                                }
                                readOnly
                                className="border-0 border-bottom"
                              />
                              <FaLock className="lock" />
                            </Col>
                          </Form.Group>
                        </Col>

                        <Col md={6} xs={6}>
                          <Form.Group className="mb-3">
                            <Form.Label column sm="2">
                              Date of Birth
                            </Form.Label>
                            <Col sm="12" className="position-relative">
                              <Form.Control
                                type="text"
                                placeholder={new Date(
                                  getstudentdata.dob
                                ).toLocaleDateString()}
                                readOnly
                                className="border-0 border-bottom"
                              />
                              <FaLock className="lock" />
                            </Col>
                          </Form.Group>
                        </Col>

                        <Col md={6} xs={6}>
                          <Form.Group className="mb-3">
                            <Form.Label column sm="2">
                              Academic year start
                            </Form.Label>
                            <Col sm="12" className="position-relative">
                              <Form.Control
                                type="text"
                                placeholder={new Date(
                                  getstudentdata.academic_year_start
                                ).toLocaleDateString()}
                                readOnly
                                className="border-0 border-bottom"
                              />
                              <FaLock className="lock" />
                            </Col>
                          </Form.Group>
                        </Col>

                        <Col md={6} xs={6}>
                          <Form.Group className="mb-3">
                            <Form.Label column sm="2">
                              Academic year end
                            </Form.Label>
                            <Col sm="12" className="position-relative">
                              <Form.Control
                                type="text"
                                placeholder={new Date(
                                  getstudentdata.academic_year_end
                                ).toLocaleDateString()}
                                readOnly
                                className="border-0 border-bottom"
                              />
                              <FaLock className="lock" />
                            </Col>
                          </Form.Group>
                        </Col>

                        {/* <Col md={6} xs={12}>
                      <Form.Group
                        className="mb-3"
                      >
                        <Form.Label column sm="2">
                        Teacher Mail ID
                        </Form.Label>
                        <Col sm="12" className="position-relative">
                          <Form.Control
                            type="text"
                            placeholder={getstudentdata && getstudentdata.teacher_email}
                            readOnly
                            className="border-0 border-bottom"
                          />
                          <FaLock className="lock"/>
                        </Col>
                      </Form.Group>
                      </Col> */}

                        {/* <Col md={6} xs={12}>
                      <Form.Group
                        className="mb-3"
                      >
                        <Form.Label column sm="2">
                        Teacher Name
                        </Form.Label>
                        <Col sm="12" className="position-relative">
                          <Form.Control
                            type="text"
                            placeholder={getstudentdata && getstudentdata.teacher_name}
                            readOnly
                            className="border-0 border-bottom"
                          />
                          <FaLock className="lock"/>
                        </Col>
                      </Form.Group>
                      </Col> */}

                        <Col md={6} xs={12}>
                          <Form.Group className="mb-3">
                            <Form.Label column sm="2">
                              Teacher Mobile No.
                            </Form.Label>
                            <Col sm="12" className="position-relative">
                              <Form.Control
                                type="text"
                                placeholder={
                                  getstudentdata && getstudentdata.mobile_number
                                }
                                readOnly
                                className="border-0 border-bottom"
                              />
                              <FaLock className="lock" />
                            </Col>
                          </Form.Group>
                        </Col>

                        <Col md={6} xs={12}>
                          <Form.Group className="mb-3">
                            <Form.Label column sm="2">
                              Parmanent Add.
                            </Form.Label>
                            <Col sm="12" className="position-relative">
                              <Form.Control
                                type="text"
                                placeholder={
                                  getstudentdata &&
                                  getstudentdata.residential_address
                                }
                                readOnly
                                className="border-0 border-bottom"
                              />
                              <FaLock className="lock" />
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
           )}
      </Container>
    </div>
  );
}

export default Profile;
