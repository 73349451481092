import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import loader from "../../assets/images/giphy.gif";
import axios from "axios";

function SNotification() {
  const school_id = localStorage.getItem("school_id");
  const token = localStorage.getItem("token");
  const [notifications, setNotifications] = useState([]);
  const [details, setDetails] = useState([]);
  const isLoading = useRef(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isLoading.current) {
      getNotification();
    }
  }, []);

  const convertTo12Hour = (time) => {
    let [hours, minutes, seconds] = time.split(":");
    hours = parseInt(hours);
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    return `${hours}:${minutes} ${ampm}`;
  };

  const getNotification = async () => {
    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}student-communication`;
      const formData = new FormData();
      formData.append("school_id", school_id);
      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      console.warn(response.data);
      const leaveData = response.data.data.map((communication) => ({
        ...communication,
        time: convertTo12Hour(communication.time),
      }));
      setNotifications(leaveData);
      isLoading.current = false;
    } catch (error) {
      isLoading.current = false;
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  return (
    <div className="App profile fee Assignment">
                  <div className="bg_color_heading padding-bottom-80">

        <div className="heading_content mb-3">
            <div className="heading_right">
              <Link to="/Dashboard">
                <IoIosArrowBack className="fs-2 text-light" />
              </Link>
              <p className="text-light ms-1">School Notifications</p>
            </div>
        </div>
        </div>
      <Container fluid>
        {/* <div className="notice_border mb-3 border"> */}

        {/* <div className=" notice_border mb-3">
          <h5 className="text-light">Notice Detail</h5>
        </div> */}

        {/* </div> */}
        {loading ? (
          <div className="img-fluidloder">
            <img src={loader} alt="Loading..." />
          </div>
        ) : (
          <Row>
            <Col md={12} sm={12} className="pb-5">
             
              <div className="round_bg1 mt-2">
                <Row className="two_option pl-0">
                  <Col md={12} sm={12}>
                    {notifications && notifications.length > 0 ? (
                      notifications.map((communication, index) => (
                        <div className="notice-board-wrap" key={index}>
              <div className="notice-list">
                <div className="post-date bg-skyblue">{communication && communication.date}</div>
                <div><h5>{communication && communication.title}</h5></div>
                <h6 className="notice-title">
                  <a href="#">
                  <p>  {communication && communication.description}</p>
                  </a>
                </h6>
                
              </div>
              </div>
                        // <Card className="mb-3" key={index}>
                        //   <Card.Header as="h5" className="text-capitalize">
                        //     {communication && communication.title}
                        //   </Card.Header>
                        //   <Card.Body>
                        //     <div className="d-flex justify-content-between py-2">
                        //       <div className="startdate d-flex">
                        //         <p className="d-block fw-bolder">Date :</p>
                        //         {communication && communication.date}
                        //       </div>
                        //       <div className="enddate d-flex">
                        //         <p className="d-block fw-bolder">Time :</p>
                        //         {communication && communication.time}
                        //       </div>
                        //     </div>
                        //     <div className="discriptiondetails">
                        //       <h6 className="d-block fw-bolder">Description</h6>
                        //       <p className="desc">
                        //         {communication && communication.description}
                        //       </p>
                        //     </div>
                        //   </Card.Body>
                        // </Card>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="7"
                          className="text-center nodataavl Nodata1"
                        >
                          No data available or something went wrong.
                        </td>
                      </tr>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default SNotification;
