import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Row, Col, Container, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import Swal from "sweetalert2";
import loader from "../../assets/images/giphy.gif";
import Splashh from "../../assets/images/holiday.jpg";

function Holiday() {
  const user_id = localStorage.getItem("user_id");
  const school_id = localStorage.getItem("school_id");
  const token = localStorage.getItem("token");
  const [user, setUser] = useState([]);
  const isLoading = useRef(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        isLoading.current = true;
        const url = `${process.env.REACT_APP_API_URL}holiday-list`;
        const formData = new FormData();
        formData.append("school_id", school_id);
        formData.append("user_id", user_id);
        setLoading(true);
        const config = {
          method: "POST",
          url: url,
          data: formData,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        };

        const response = await axios(config);
        setUser(response.data.data);

        const completeImageUrl = `${response.data.url}`;
        setImage(completeImageUrl);
        isLoading.current = false;
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        isLoading.current = false;
        console.error("Error fetching user data:", error);
        setLoading(false); // Set loading to false in case of an error
        // You might want to show an error message to the user here
      }
    };

    if (!isLoading.current) {
      fetchData();
    }
  }, [user_id, token]);

  return (
    <div className="App profile Attendance holiday">
        <div className="bg_color_heading padding-bottom-80">
          <div className="heading_content">
            <div className="heading_right">
              <Link to="/Dashboard">
                <IoIosArrowBack className="fs-2 text-light" />
              </Link>
              <p className="text-light ms-1">School Holiday</p>
            </div>
          </div>
        </div>
    

        {loading ? (
          <div className="img-fluidloder">
            <img src={loader} alt="Loading..." />
          </div>
        ) : (
              <div className="round_bg margin-top-minus-60 ">
                  <Container>
              <Row>
                        {user && user.length > 0 ? (
                          user.map((holiday, index) => (
                           <Col xs={6} md={3}>
                            <div key={index}>
                              <div className="holiday_list Attendance">
                                <div className="card-image">
                                  <img  variant="top"
                                  src={`${image}/${holiday.holiday_image}`}
                                  alt={holiday.holiday_title}/>
                                </div>
                                <div>
                                  <div className="titleholiday">
                                    {holiday && holiday.holiday_types} Holiday
                                  </div>
                                  <div className="card-details">
                                    <h4>{holiday && holiday.title}</h4>
                                    <div className="d-flex justify-content-between">
                                      <div className="startdate">
                                        <small className="d-block">
                                          Start Date
                                        </small>
                                        {new Date(
                                          holiday.holiday_start_date
                                        ).toLocaleDateString("en-GB")}
                                      </div>
                                      <div className="enddate">
                                        <small className="d-block">
                                          End Date
                                        </small>
                                        {new Date(
                                          holiday.holiday_end_date
                                        ).toLocaleDateString("en-GB")}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            </Col>
                          ))
                        ) : (
                          <Col md={12} className="text-center">
                            <img
                              src={Splashh}
                              alt="Gallery_img"
                              className="img-fluid"
                              style={{
                                height: "80%",
                                width: "50%",
                                borderRadius: "15px",
                              }}
                            />
                            <h1>Holiday Not Available</h1>
                          </Col>
                        )}
                      </Row>
                      </Container>
              </div>
        
        )}
    
    </div>
  );
}

export default Holiday;
