import React, { useState, ChangeEvent, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Container,
  Card,
  Table,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { ImFilePdf } from "react-icons/im";
import axios from "axios";
import pdf from "../../assets/svg/icons8-export-pdf-60.png";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function SubmitAssignment() {
  const navigate = useNavigate();
  const school_id = localStorage.getItem("school_id");
  const class_id = localStorage.getItem("class_id");
  const student_id = localStorage.getItem("student_id");
  const assignment_ids = localStorage.getItem("id");
  // const submission_date1 = localStorage.getItem("submission_date");
  const submitted_date2 = localStorage.getItem("submitted_date");
  const studentuser_id = localStorage.getItem("user_id");

  const sectionId = localStorage.getItem("section_id");
  const subId = localStorage.getItem("subject_id");
  const desc = localStorage.getItem("description");
  const created_datess = localStorage.getItem("created_date");
  const submission_datess = localStorage.getItem("submission_date");

  const token = localStorage.getItem("token");
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [techerid, setteacher] = useState();
  const [file, setFile] = useState();

  // For Uplaod Pdf file
  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const isLoading = useRef(false);

  useEffect(() => {
    if (!isLoading.current) {
      loadTeachers();
    }
  }, []);

  // const [formData, setFormData] = useState({
  //   teacher_id: "",
  //   school_id: school_id,
  //   pdf: "",
  //   submitted_date: submitted_date2,
  //   description: "solved",
  //   student_id: 70,
  //   submission_date: submission_date1,
  //   assignment_id: assignment_ids,
  // });

  // Teacher List API
  const loadTeachers = async () => {
    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}teacher-lists`;
      const formData = new FormData();
      formData.append("school_id", school_id);
      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      const completeImageUrl = response.data.url;
      const teachersData = response.data.data;
      console.warn(teachersData);
      setTeachers(teachersData);
      isLoading.current = false;
    } catch (error) {
      isLoading.current = false;
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleInputChange = (event) => {
    // const { name, value, file } = event.target;
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   [name]: value, file,
    // }));
  };
  const submitAssign = async () => {
    if (!techerid) {
      Swal.fire({
        icon: "warning",
        title: "Error",
        text: "Please select a teacher.",
        timer: 2000,
      });
      return; // Prevent submission if no teacher selected
    }

    if (!file) {
      Swal.fire({
        icon: "warning",
        title: "Error",
        text: "Please select a PDF file.",
        timer: 2000,
      });
      return;
    }
    setLoading(true);

    try {
      const url = `${process.env.REACT_APP_API_URL}student-assignment-submitted`;

      const formData = new FormData();
      formData.append("school_id", school_id);
      formData.append("teacher_id", techerid);
      formData.append("class_id", class_id);
      formData.append("student_id", studentuser_id);
      formData.append("section_id", sectionId);
      formData.append("subject_id", subId);
      formData.append("pdf", file);
      formData.append("description", desc);
      formData.append("submitted_date", created_datess);
      formData.append("submission_date", submission_datess);
      formData.append("id", assignment_ids);

      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      const data = response.data;

      if (response.status === 200) {
        if (data.status === 1) {
          Swal.fire({
            icon: "success",
            title: "Assignment Submitted Successfully!",
            text: data.message,
            timer: 2500,
          });
          navigate("/Dashboard");
        } else if (data.status === 0) {
          Swal.fire({
            icon: "error",
            title: "",
            text: data.message || "Unknown Error",
            timer: 3000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Out of Date",
            text: data.message || "Unknown Error",
            timer: 3000,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please select the correct teacher who assigned the assignment.",
          timer: 3500,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select the correct teacher who assigned the assignment.",
        timer: 3500,
      });
      console.error(
        "Please select the correct teacher who assigned the assignment:",
        error
      );
    } finally {
      setLoading(false);
    }
  };

  // const handleButtonClick = (pdfs, date, teacherId, subId) => {
  //   localStorage.setItem("submission_date", date);
  //   localStorage.setItem("pdf", pdfs);
  //   localStorage.setItem("teacher_id", teacherId);
  //   localStorage.setItem("subject_id", subId);
  // };

  const handleButtonClicktecherid = (teacherid) => {
    // Your custom logic here
    setteacher(teacherid);
  };
  return (
    <div className="App profile fee Assignment">
      <div className="bg_color_heading padding-bottom-80">
        <div className="heading_content">
          <div className="heading_right">
            <Link to="/Dashboard">
              <IoIosArrowBack className="fs-2 text-light" />
            </Link>
            <p className="text-light ms-1">Submit Assignment</p>
          </div>
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col md={12} sm={12}>
            <div className="round_bg">
              <Container>
                <Form className="ask_doubt">
                  <Form.Group
                    className="mb-3 w-100"
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label column sm="2">
                      Class Teacher
                    </Form.Label>
                    <Col sm="12">
                      <Form.Control
                        as="select"
                        className="border-0 border-bottom"
                        name="teacher_id"
                        // value={formData.teacher_id}
                        onChange={(e) => {
                          setteacher(e.target.value);
                          handleButtonClicktecherid(e.target.value);
                        }}
                      >
                        <option value="">Select Teacher</option>
                        {teachers.map((teacher) => (
                          <option
                            key={teacher.teacher_id}
                            value={teacher.teacher_id}
                            onClick={() =>
                              handleButtonClicktecherid(teacher.teacher_id)
                            }
                          >
                            {teacher.teacher_name} - {teacher.subject_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group className="mb-3 w-100" controlId="dowtTitle">
                    <Form.Label column sm="2">
                      Submission Date
                    </Form.Label>
                    <Col sm="12">
                      <Form.Control
                        type="text"
                        placeholder="submission_date"
                        name="dowt_title"
                        value={submission_datess}
                        onChange={handleInputChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Upload Assignment File</Form.Label>
                    <Form.Control type="file" onChange={handleChange} />
                  </Form.Group>

                  <Button
                    size="lg"
                    className="mybutton w-100"
                    onClick={submitAssign}
                  >
                    SUBMIT ASSIGNMENT <ImFilePdf className="ms-2 fs-5" />
                    {loading && <Spinner animation="border" />}
                  </Button>
                </Form>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SubmitAssignment;
