import { React, useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Container, Card } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import Form from 'react-bootstrap/Form';
// import quiz from "../../assets/images/quiz.webp";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import loader from '../../assets/images/giphy.gif';
import staticImage  from '../../assets/images/static1.jpg';

function QuizList() {
  const history = useNavigate();

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const user_id = localStorage.getItem("user_id");
  const coursid = localStorage.getItem("coursid");
  const school_id = localStorage.getItem("school_id");
   const group_id = localStorage.getItem("group_id");
  //  const id = localStorage.getItem("subid");
  //  alert(id);
  //  const model_id = localStorage.getItem("model_id");
  //  alert(group_id);
  
  const token = localStorage.getItem("token");
  const [users, setstudentCoures] = useState([]);
  const isLoading = useRef(false);
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState([]);
  const [groupCount, setGroupCount] = useState(0);
  const [grouplist, getGroupList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [subjectid, setSubjectIds] = useState("");


  useEffect(() => {
    if(!isLoading.current){
  loadUser();
  studentGroup();
  studentGroupList();
    }
  }, []);

  const loadUser = async () => {
    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}getSubject`;
  
      const formData = new FormData();
      formData.append("category_id", coursid);
      formData.append('school_id', school_id);
  
      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
  
      const response = await axios(config);
      const subjects = response.data.data;
      console.warn(subjects);
      setstudentCoures(subjects);
      
      
  //     const subjectIds = subjects.map(subject => subject.id);
  //     console.warn(subjectIds);
  // // alert(subjectIds);
  //     setSubjectIds(subjectIds);

   
    
      const completeImageUrl = `${response.data.url}`;
      setImage(completeImageUrl);
      isLoading.current = false;
    } catch (error) {
      isLoading.current = false;
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };
  

  const studentGroup = async () => {
    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}student-added-group-or-not`;
      const formData = new FormData();
      formData.append("user_id", user_id);
      formData.append('school_id',school_id );
      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios(config);
      setGroupCount(response.data.count);
      console.warn(response.data);
      isLoading.current = false;
    } catch (error) {
      isLoading.current = false;
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setLoading(false);
    }
  };


  const studentGroupList = async () => {
    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}student-added-exam-group-lists`;
      const formData = new FormData();
      formData.append("user_id", user_id);
      formData.append('school_id',school_id );
     
      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
       const response = await axios(config);
       getGroupList(response.data.data);
       const groupData = response.data.data;
       if (groupData.length > 0) {
        const firstGroup = groupData[0];
        const { group_id, models_id ,subcategory_id, chapter_id, totalmarks,time} = firstGroup;
        localStorage.setItem('group_id', group_id);
        localStorage.setItem('model_id', models_id);
        localStorage.setItem('subcategory_id', subcategory_id);
        localStorage.setItem('chapter_id', chapter_id);
        localStorage.setItem('totalmarks', totalmarks);
        localStorage.setItem('time', time);
       }
      console.warn(response.data.data);
      isLoading.current = false;
    } catch (error) {
      isLoading.current = false;
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setLoading(false);
    }
  };


  const handleClick = (id, coursid,) => {
    localStorage.setItem('subid', id);
      // localStorage.setItem("group_id", group_id);
    
    window.location.href = "/QuizList";
  };

  const handleButtonClick = ( models_id, group_id, subcategory_id,chapter_id, totalmarks,time) => {
    localStorage.setItem('group_id', group_id);
    localStorage.setItem('model_id', models_id);
    localStorage.setItem('subcategory_id', subcategory_id);
    localStorage.setItem('chapter_id', chapter_id);
    localStorage.setItem('totalmarks', totalmarks);
    localStorage.setItem('time', time);

    history(`/Quiz`); 
  };

  return (
    <div className="App profile QuizCourse">
      <Container>
        <Row className="heading_content">
          <Col md={6} sm={6} xs={6}>
            <div className="heading_right">
              <Link to="/QuizCourse">
                <IoIosArrowBack className="fs-2 text-light" />
              </Link>
              <p className="text-light ms-1">Play Quiz</p>
            </div>
          </Col>
        </Row>
        {loading ? (
          <div className="img-fluidloder">
          <img
          src={loader}
          alt="quiz"
          
        />
        </div>
        ) : (
          groupCount > 0 ?(
            <>
            <h5> Please select the exam group</h5>
            <Form.Select
  aria-label="Default select example"
  onChange={(e) => {
    const selectedGroup = e.target.value;
    const {models_id, group_id,subcategory_id,chapter_id,totalmarks, time } = grouplist.find(group => group.groupname === selectedGroup);
    setSelectedGroup(selectedGroup);
    handleButtonClick(models_id, group_id,subcategory_id,chapter_id, totalmarks, time); 
  }}
>
  <option>Select Group</option>
  {grouplist && grouplist.length > 0 ? (
    grouplist.map((glist) => (
      <option key={glist.group_id}>
        {glist.groupname}
      </option>
    ))
  ) : (
    <option value="" disabled>
      No groups available
    </option>
  )}
</Form.Select>

          </>
          ) :(
        <Row className="quiz_box gy-3">
          {users && users.length > 0 ? (
            users.map((user, index) => (
              <Col key={index} md={6} sm={6} xs={6}>
                {/* {/ <Link to="/QuizList"> /} */}
                <Link onClick={() => handleClick(user.id)}>
                  <Card className="quiz_box">
                    <div className="quiz_course">
                    <img
                            src={user.image ? `${image}/${user.image}` : staticImage}
                            alt="quiz"
                            className="img-fluid"
                          />
                      <h5>Subject: {user.name}</h5>
                    </div>
                  </Card>
                </Link>
              </Col>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="text-center nodataavl Nodata">
                No data available or something went wrong.
              </td>
            </tr>
          )}
        </Row>
          )
         )}
      </Container>
    </div>
  );
}

export default QuizList;
