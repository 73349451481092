import { React, useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Container, Card } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
// import quiz from "../../assets/images/quiz.webp";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import loader from "../../assets/images/giphy.gif";

function GroupChat() {
  const history = useNavigate();

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const user_id = localStorage.getItem("user_id");
  const coursid = localStorage.getItem("coursid");
  const school_id = localStorage.getItem("school_id");
  const group_id = localStorage.getItem("group_id");
  //  const model_id = localStorage.getItem("model_id");
  //  alert(group_id);

  const token = localStorage.getItem("token");
  const [users, setstudentCoures] = useState([]);
  const isLoading = useRef(false);
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState([]);
  const [groupCount, setGroupCount] = useState(0);
  const [grouplist, getGroupList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");

  useEffect(() => {
    if (!isLoading.current) {
      loadUser();
      studentGroup();
      studentGroupList();
    }
  }, []);

  const loadUser = async () => {
    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}getSubject`;

      const formData = new FormData();
      formData.append("category_id", coursid);
      formData.append("school_id", school_id);

      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      const demo = response.data.data;
      console.warn(demo);
      setstudentCoures(demo);
      const completeImageUrl = `${response.data.url}`;
      setImage(completeImageUrl);
      isLoading.current = false;
    } catch (error) {
      isLoading.current = false;
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const studentGroup = async () => {
    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}student-added-group-or-not`;
      const formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("school_id", school_id);
      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios(config);
      setGroupCount(response.data.count);
      console.warn(response.data);
      isLoading.current = false;
    } catch (error) {
      isLoading.current = false;
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setLoading(false);
    }
  };

  // const studentGroupList = async () => {
  //   try {
  //     isLoading.current = true;
  //     setLoading(true);
  //     const url = `${process.env.REACT_APP_API_URL}groupchat-group-lists`;

  //     const formData = new FormData();
  //     formData.append("user_id", user_id);
  //     formData.append('school_id',school_id );

  //     const config = {
  //       method: "post",
  //       url: url,
  //       data: formData,
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "multipart/form-data",
  //       },
  //     };
  //      const response = await axios(config);
  //      getGroupList(response.data.data);
  //      const groupData = response.data.data;
  //      if (groupData.length > 0) {
  //       const firstGroup = groupData[0];
  //       const { group_id, models_id } = firstGroup;
  //       localStorage.setItem('group_id', group_id);
  //       localStorage.setItem('model_id', models_id);
  //      }
  //     console.warn(response.data.data);
  //     isLoading.current = false;
  //   } catch (error) {
  //     isLoading.current = false;
  //     console.error("There was a problem with the fetch operation:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const studentGroupList = async () => {
    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}groupchat-group-lists`;

      const formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("school_id", school_id);

      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);

      if (response.data.status === 1 && response.data.message === "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.data,
        });
      } else {
        getGroupList(response.data.data);
        const groupData = response.data.data;
        if (groupData.length > 0) {
          const firstGroup = groupData[0];
          const { group_id, models_id } = firstGroup;
          localStorage.setItem("group_id", group_id);
          localStorage.setItem("model_id", models_id);
        }
      }

      isLoading.current = false;
    } catch (error) {
      isLoading.current = false;
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setLoading(false);
    }
  };

  // const handleClick = (id, coursid,) => {
  //   localStorage.setItem('subid', id);
  //     // localStorage.setItem("group_id", group_id);

  //   window.location.href = "/Dashboard";
  // };

  const handleButtonClick = (models_id, group_id, groupName) => {
    localStorage.setItem("group_id", group_id);
    localStorage.setItem("model_id", models_id);
    localStorage.setItem("groupName", groupName);
    history(`/GroupChatting`); // Redirect to the quiz page
  };

  return (
    <div className="App profile QuizCourse">
      <div className="bg_color_heading padding-bottom-110">
        <div className="heading_content">
          <div className="heading_right">
            <Link to="/Dashboard">
              <IoIosArrowBack className="fs-2 text-light" />
            </Link>
            <p className="text-light ms-1">Select Group</p>
          </div>
        </div>
      </div>
      <div className="round_bg margin-top-minus-110">

      <Container fluid>
        <>
          <h5 className="text-white my-3">
           
            Please select the exam group
          </h5>
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => {
              const selectedGroup = e.target.value;
              const { models_id, group_id } = grouplist.find(
                (group) => group.groupname === selectedGroup
              );
              setSelectedGroup(selectedGroup);
              handleButtonClick(models_id, group_id, selectedGroup);
            }}
          >
            <option>Select Group</option>
            {grouplist && grouplist.length > 0 ? (
              grouplist.map((glist) => (
                <option className="text-capitalize" key={glist.group_id}>
                  {glist.groupname}
                </option>
              ))
            ) : (
              <option value="" disabled>
                No groups available
              </option>
            )}
          </Form.Select>
        </>
      </Container>
    </div>
    </div>
  );
}

export default GroupChat;
