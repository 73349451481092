import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Container, Card } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import axios from "axios";
import loader from '../../assets/images/giphy.gif';

const url = new URL(window.location.href);
const chapter1 = url.searchParams.get("id");

const url1 = new URL(window.location.href);
const course = url1.searchParams.get("course");


// alert(course);
function Old_Results() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const user_id = localStorage.getItem("user_id");
  // alert(user_id)
  const school_id = localStorage.getItem("school_id");
  const name = localStorage.getItem("name");
  // alert(group_idssss);
  const token = localStorage.getItem("token");
  const [studentChapter, setStudentChapter] = useState([]);
  const isLoading = useRef(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
   if(!isLoading.current){
  loadUser();
   }
  }, []);

  const loadUser = async () => {
    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}student-exam-list`;
      const formData = new FormData();
      formData.append("kids_id",user_id );
      formData.append("school_id", school_id);

      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios(config);
      const demo = response.data.kids_exam_lists;
      // alert(demo);
      console.warn(demo);
      setStudentChapter(demo);
      isLoading.current = false;
    } catch (error) {
      isLoading.current = false;
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleClick = (exam_id) => {
    localStorage.setItem('exam_id', exam_id);
    window.location.href = `/Results`;
  };

  return (
    <div className="App profile Quizlist">
      <Container>
        <Row className="heading_content">
          <Col md={6} sm={6} xs={6}>
            <div className="heading_right">
              <Link to="/Dashboard">
                <IoIosArrowBack className="fs-2 text-light" />
              </Link>
              {/* <p className="text-light ms-1">Play Quiz</p> */}
            </div>
          </Col>
        </Row>
        {loading ? (
          <div className="img-fluidloder">
          <img
          src={loader}
          alt="quiz"
          
        />
        </div>
        ) : (
        <Row className="quiz_List gy-3">
        {studentChapter && studentChapter.length > 0 ?(
            studentChapter.map((chapter) => (
              <Col md={12} sm={12} xs={12}>
                
                <Card className="list">
                  <div className="quiz_name">
                    <h4 className="text-capitalize">{chapter.user_name}</h4>
                    <p>{chapter.modal}</p>
                    <span>Date : {chapter.date} </span>
                    {/* <span>{chapter.description}</span> */}
                  </div>
                  <div className="quiz_btn1">
                    <Link onClick={() => handleClick(chapter.exam_id, chapter.name)}
                    >
                    <div className="d-flex justify-content-end">
                    <span className="btn_quiz">
                       view Result
                   </span>
            </div>
            </Link>
                  </div>
                </Card>
              </Col>
           ))
           ) : (
             <tr>
               <td colSpan="7" className="text-center nodataavl Nodata">
               You have not attempt any exam.
               </td>
             </tr>
           )}
        </Row>
        )}
      </Container>
    </div>
  );
}

export default Old_Results;

