import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import group from "../../assets/images/Group.png";
import group1 from "../../assets/images/Group-1.png";
import group2 from "../../assets/images/Group-2.png";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ue from "../../assets/images/ue.png";
import ue2 from "../../assets/images/ue2.png";

import { Link, useLocation } from "react-router-dom";
import Splash from "../../assets/images/login.png";
import { ArrowRight, EyeSlash, EyeFill } from "react-bootstrap-icons";
import "../../assets/css/login.css";

function App() {
  const handleTogglePassword = () => {
    const passwordInput = document.getElementById("password");
    const type =
      passwordInput.getAttribute("type") === "password" ? "text" : "password";
    passwordInput.setAttribute("type", type);
  };

  const [showComponent, setShowComponent] = useState(true);
  const [hideComponent, sethideComponent] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowComponent(false);
      sethideComponent(true);
    }, 2000);
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const [name, setname] = useState("");
  const [password, setPassword] = useState("");
  const [browserId, setBrowserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState("");

  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const onInputChangename = (e) => {
    setname(e.target.value);
  };

  const onInputChangepassword = (e) => {
    setPassword(e.target.value);
  };

  const Submit = async () => {
    try {
      if (!name) {
        toast.error("Please enter a valid Student Email.");
        return;
      }
      if (!password || password.length < 6) {
        toast.error("Password must be at least 6 characters long.");
        return;
      }

      setLoading(true);

      let url = `${process.env.REACT_APP_API_URL}StudentUserlogin`;
      const formData = new FormData();
      formData.append("email", name);
      formData.append("password", password);
      const config = {
        method: "POST",
        url: url,
        data: formData,
      };

      axios(config)
        .then(function (response) {
          if (response.data.status === 0) {
            const message = response.data.message;
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: message,
              timer: 2000,
            });
          } else if (response.data.status === 1) {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Login successfully.",
              timer: 2000,
            }).then((result) => {
              // navigate('/dashboard');
              localStorage.setItem("user_id", response.data.data.id);
              localStorage.setItem("school_id", response.data.data.school_id);
              localStorage.setItem("token", response.data.bear_token);
              localStorage.setItem(
                "multi_device_session",
                response.data.multi_device_session
              );
              // window.localStorage.setItem('isLoggedIn', true);
              // localStorage.setItem("isLoggedIn", "true");
              // setLoggedIn(true);
              // Redirect to dashboard or any other authenticated route
              navigate("/dashboard");
            });
          } else {
            toast.error(response.data.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      setLoading(false);
    }
  };

  return (
    <div className="new_body">
      <Container>
        <Row className="height_login vertical-align-middle align-items-center justify-contain-center">
          <Col xl={12} className="">
            <div className="card rounded-4 border-0 position-relative">
              <div className="card-body p-0 z-1 rounded-4 bg-white">
                <Row className="m-0">
                  <Col md={6} lg={5}>
                    <div className="d-flex align-items-center justify-content-between my-3">
                      <div className="loginlogo_new eductaionlogowidth">
                        <img src={ue} alt="logo" className="" />
                      </div>
                      <div className="loginlogo_new height_auto">
                        <img src={ue2} alt="logo2" className="" />
                      </div>
                    </div>
                    <div className="sign_in">
                      <h4 className="fs-20 text-black loginschool">Login</h4>
                      <h6 className="mb-2">Enter your account details</h6>
                      <Form className="mt-4">
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="text"
                            required
                            value={name}
                            onChange={onInputChangename}
                            className="form-control-login border-0 border-bottom rounded-0 px-2"
                            placeholder="Enter Email"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3 password-container">
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            className="form-control-login border-0 border-bottom rounded-0 px-2"
                            value={password}
                            onChange={onInputChangepassword}
                            required
                          />
                          {showPassword ? (
                            <EyeSlash
                              className="fs-3 eye"
                              onClick={togglePasswordVisibility}
                            />
                          ) : (
                            <EyeFill
                              className="fs-3 eye"
                              onClick={togglePasswordVisibility}
                            />
                          )}
                        </Form.Group>
                        <div className="row d-flex justify-content-end mt-4 mb-2">
                          <div className="mb-3">
                            <a href="#" className="forgetpsw">
                              Forgot Password?
                            </a>
                          </div>
                        </div>
                        <div className="text-center">
                          <Button
                            type="submit"
                            onClick={Submit}
                            disabled={loading}
                            className="btn login-button btn-color-custum btn-block"
                          >
                            Login
                            {loading && <Spinner animation="border" />}
                          </Button>
                        </div>
                      </Form>
                      {/* <div className="d-flex justify-content-between align-items-center my-5">
                        <a href="#" className="forgetpsw mt-5">
                          Don’t have an account?
                        </a>
                        <Button type="button" className="btn btn-secondary sign_up_btn mt-5">
                          Sign up
                        </Button>
                      </div> */}
                    </div>
                  </Col>
                  <Col
                    md={6}
                    lg={7}
                    className="sign text-light d-none d-md-block position-relative"
                  >
                    <div className="ms-lg-3 ms-1">
                      <h2 className="mt-5 fs_top">
                        Welcome to <br />
                        <span>Student</span> portal
                      </h2>
                      <p className="text-start">Login to access your account</p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="shapesicon d-none d-md-block">
                {/* Ensure you import and use images properly */}
                <img
                  src={group}
                  alt="shapes"
                  fluid
                  className="img-fluid shapes s1"
                />
                <img
                  src={group1}
                  alt="shapes"
                  fluid
                  className="img-fluid shapes s2"
                />
                <img
                  src={group2}
                  alt="shapes"
                  fluid
                  className="img-fluid shapes s3"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
