import React, { useState, useRef, useEffect } from 'react';
import { FaArrowUp, FaAngleLeft } from "react-icons/fa";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { AiFillAudio } from "react-icons/ai";
import { AudioRecorder } from 'react-audio-voice-recorder';
import { GrAttachment } from "react-icons/gr";
import { IoMenu } from "react-icons/io5";

// Loader Component
const Loader = () => (
  <div className="loader">
    <div className="spinner">
    <div class="typing-loader"></div>
    </div>
  </div>
);

const Aipage = () => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioFileName, setAudioFileName] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [isAiTyping, setIsAiTyping] = useState(false); // State for AI typing
  const chatMessagesRef = useRef(null);
  const inputRef = useRef(null);

  // Handle file change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    setFileName(file.name);
  };

  // Handle audio recording completion
  const handleAudioComplete = (blob) => {
    setAudioBlob(blob);
    setAudioFileName('recording.webm'); // Setting a default name for the audio file
  };

  const [texts, setTexts] = useState(() => {
    const savedTexts = localStorage.getItem('texts');
    return savedTexts ? JSON.parse(savedTexts) : [];
  });

  // Update local storage whenever texts change
  useEffect(() => {
    localStorage.setItem('texts', JSON.stringify(texts));
  }, [texts]);

  // Handle input change
  const handleChange = (e) => {
    setInputText(e.target.value);
  };

  const sendMessage = async () => {
    if (inputText.trim() === '' && !audioBlob && !file) return;

    const newMessage = { type: 'user', text: inputText.trim() || audioFileName || fileName };
    localStorage.setItem('inputText', inputText);

    // Add user message to state
    setMessages([...messages, newMessage]);
    setInputText(''); // Clear the input field
    setAudioBlob(null);
    setAudioFileName('');
    setFile(null);
    setFileName('');

    // Scroll to bottom after updating messages
    setTimeout(() => {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }, 100);

    // Set AI typing state to true
    setIsAiTyping(true);

    try {
      if (audioBlob || file) {
        // Handle audio or file upload
        const formData = new FormData();
        if (audioBlob) {
          formData.append('file', audioBlob, 'recording.webm');
          formData.append('model', 'whisper-1');
        } else if (file) {
          formData.append('file', file);
        }

        const response = await axios.post(
          'https://api.openai.com/v1/audio/translations',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer sk-proj-Yid8u9ipkOVGKVbN4q8aT3BlbkFJIq0fLKxnaEKqf7qRL3Fk`,
            },
          }
        );

        if (response.data.text) {
          const aiResponse = { type: 'ai', text: response.data.text };
          setMessages((prevMessages) => [...prevMessages, aiResponse]);
        } else {
          setMessages((prevMessages) => [...prevMessages, { type: 'ai', text: 'No response from API' }]);
        }
      } else {
        // Make API call to OpenAI for text message
        const response = await axios.post(
          'https://api.openai.com/v1/chat/completions',
          {
            model: 'gpt-4',
            messages: [
              {
                role: 'user',
                content: inputText.trim(),
              },
            ],
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer sk-proj-Yid8u9ipkOVGKVbN4q8aT3BlbkFJIq0fLKxnaEKqf7qRL3Fk`,
            },
          }
        );

        if (response.data.choices && response.data.choices.length > 0) {
          const aiResponse = { type: 'ai', text: response.data.choices[0].message.content };
          setMessages((prevMessages) => [...prevMessages, aiResponse]);
        } else {
          setMessages((prevMessages) => [...prevMessages, { type: 'ai', text: 'No response from API' }]);
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setMessages((prevMessages) => [...prevMessages, { type: 'ai', text: 'Error communicating with the API' }]);
    } finally {
      // Set AI typing state to false
      setIsAiTyping(false);
    }
  };

  useEffect(() => {
    // Scroll to bottom of chat messages on initial load and on new message
    chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
  }, [messages]);

  // Sidebar state and functions
  const [chatsidebar, setChatsidebar] = useState(false);
  const sidebarchat = () => {
    setChatsidebar(!chatsidebar);
  };
  const closesidebar = () => {
    setChatsidebar(false);
  };

  const loadChatHistory = () => {
    const savedHistory = localStorage.getItem('chatHistory');
    return savedHistory ? JSON.parse(savedHistory) : [];
  };

  // Function to save chat history to local storage
  const saveChatHistory = (messages) => {
    localStorage.setItem('chatHistory', JSON.stringify(messages));
  };

  useEffect(() => {
    saveChatHistory(messages); // Save chat history to localStorage whenever messages change
  }, [messages]);

  return (
    <div className="chat-container">
      <div className="chat-header d-flex justify-content-start gap-2 align-items-center">
        <Link to="/Dashboard" className="text-white d-flex ">
          <FaAngleLeft />
        </Link>
        <p className="pb-0 mb-0">
          Chat with AI
          <span className="blinking-green"></span>
        </p>
      </div>
      <div className="chat-messages" ref={chatMessagesRef}>
        {messages.map((message, index) => (
          <div
            key={index}
            className={`message ${message.type === 'ai' ? 'ai-message' : 'user-message'}`}
          >
            {message.text}
          </div>
        ))}
        {isAiTyping && <Loader />} {/* Show loader when AI is typing */}
      </div>
      <div className="chat-input-container">
        <div className="position-relative">
          <textarea
            ref={inputRef}
            className="chat-input"
            placeholder="Message Here"
            value={inputText}
            onChange={handleChange}
          />
          <AudioRecorder
            onRecordingComplete={handleAudioComplete}
            audioTrackConstraints={{
              noiseSuppression: true,
              echoCancellation: true,
            }}
            downloadOnSavePress={false}
            downloadFileExtension="webm"
          />
          {fileName && <div className="file-name">{fileName}</div>}
          <div className="inputfilechoose">
            <input type="file" id="choosefile" onChange={handleFileChange} />
            <label htmlFor="choosefile">
              <GrAttachment />
            </label>
          </div>
        </div>
        <button className="send-button" onClick={sendMessage}>
          <FaArrowUp />
        </button>
      </div>
    </div>
  );
};

export default Aipage;
