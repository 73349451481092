import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Card, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { ImFilePdf } from "react-icons/im";
import axios from "axios";
import loader from "../../assets/images/giphy.gif";
import score from "../../assets/svg/Grade_Pertange.svg";

function Result() {
  const user_id = localStorage.getItem("user_id");
  const model_id = localStorage.getItem("model_id");
  const school_id = localStorage.getItem("school_id");
  const chapter_id = localStorage.getItem("chapter_id");
  const models_id = localStorage.getItem("models_id");
  const exam_id = localStorage.getItem("exam_id");
  const name = localStorage.getItem("name");
  const model = localStorage.getItem("modal");
  const questionsLength = localStorage.getItem("questionsLength");
  const totalMark = localStorage.getItem("totalMark");
  const questionsCount = localStorage.getItem("noq");
  const group_id = localStorage.getItem("group_id");

  const token = localStorage.getItem("token");
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [percent, setPercent] = useState("");
  const isLoading = useRef(false);

  useEffect(() => {
    if (!isLoading.current) {
      loadResult();
    }
  }, []);

  const loadResult = async () => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}get-score-test-result`;
      const formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("model_id", model_id);
      formData.append("school_id", school_id);
      formData.append("questionsLength", questionsLength);
      formData.append("name", name);
      formData.append("group_id", group_id);
      formData.append("exam_id", exam_id);

      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      const demo = response.data.data;
      setResult(demo);
      setPercent(response.data.percent);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App profile Result">
      <Container>
        <Row className="heading_content">
          <Col md={6} sm={6} xs={6}>
            <div className="heading_right">
              <Link to="/Dashboard">
                <IoIosArrowBack className="fs-2 text-light" />
              </Link>
            </div>
          </Col>
        </Row>

        {loading ? (
          <div className="img-fluidloder">
            <img src={loader} alt="Loading" />
          </div>
        ) : result === null ? (
          <Row>
            <Col md={12} className="text-center">
              <p className="text-dark">You have not attempted any exam.</p>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col md={12} sm={12} xs={12}>
              <div className="score_box">
                <div className="score">
                  <img src={score} className="img-fluid" alt="Score" />
                  <div className="grade">
                    <span>{percent}%</span>
                  </div>
                </div>
              </div>
            </Col>

            <Row>
              <Col md={12} sm={12}>
                <div className="round_bg">
                  <Container>
                    <Row>
                      <Col md={12} sm={12}>
                        <div className="stu_name">
                          {totalMark > 60 ? (
                            <span>You are Excellent, </span>
                          ) : (
                            <span>You need to improve, </span>
                          )}
                          <p>{name}</p>
                        </div>
                      </Col>

                      <Col md={12} sm={12}>
                        <Card className="result">
                          <div className="result_card">
                            <Table className="mb-0">
                              <tbody>
                                <tr>
                                  <th colSpan="2" className="head">
                                    {model}
                                  </th>
                                </tr>
                                <tr>
                                  <td>Total Number of Questions</td>
                                  <td>{questionsCount}</td>
                                </tr>
                                <tr>
                                  <td>Total Attempt</td>
                                  <td>{result.correct_ans + result.incorrect_ans}</td>
                                </tr>
                                <tr>
                                  <td>Total Skipped</td>
                                  <td>{questionsCount - (result.correct_ans + result.incorrect_ans)}</td>
                                </tr>
                                <tr>
                                  <td>Correct Answers</td>
                                  <td>{result.correct_ans}</td>
                                </tr>
                                <tr>
                                  <td>Incorrect Answers</td>
                                  <td>{result.incorrect_ans}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Card>

                        <Link to="" className="download">
                          <Button size="lg" className="mybutton" onClick={() => window.print()}>
                            DOWNLOAD PDF <ImFilePdf className="ms-2 fs-5" />
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Col>
            </Row>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default Result;
