import { React, useState, useEffect} from "react";
import { Row, Col, Container, Card, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { GoShareAndroid } from "react-icons/go";
import score from "../../assets/svg/Grade_Pertange.svg";
import { ImFilePdf } from "react-icons/im";
import axios from "axios";

function Results() {
  const user_id = localStorage.getItem("user_id");
  const exam_id = localStorage.getItem("exam_id");
  const parent_id = localStorage.getItem("parent_id");
  const school_id = localStorage.getItem("school_id");
  const chapter_id = localStorage.getItem("chapter_id");
  const name = localStorage.getItem("name");
  const model = localStorage.getItem("modal");
  const questionsLength = localStorage.getItem("questionsLength");
  const questionsanswers = localStorage.getItem("answers.length");
  console.warn(questionsanswers);
  const totalPercent = localStorage.getItem("totalPercent");
  const group_idss = localStorage.getItem("group_idss");

  const token = localStorage.getItem("token");
  const [result, getResult] = useState([]);
  const [loading, setLoading] = useState(true);
  const [percent, setPercent] = useState("");
  const [marks, getMarks] = useState("");

  useEffect(() => {
  loadUser();
  }, []);

  const loadUser = async () => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}student-examlist-report`;
      const formData = new FormData();
      formData.append("kids_id", user_id);
      formData.append("school_id", school_id);
      formData.append("exam_id", exam_id);

      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      const demo = response.data;
      const totalMark = response.data.total_percentage;
      getMarks(totalMark);
      // alert(totalMark);
      localStorage.setItem("totalMark", totalMark);
      console.warn(demo);
      getResult(demo);

      // setPercent(response.data.percent);
      console.warn(percent);

      
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      // } finally {
      //   setTimeout(() => {
      //     setLoading(false);
      //   }, 1000);
    }
  };

  return (
    <div className="App profile Result">
      <Container>
        <Row className="heading_content">
          <Col md={6} sm={6} xs={6}>
            <div className="heading_right">
              <Link to="/Dashboard">
                <IoIosArrowBack className="fs-2 text-light" />
              </Link>
            </div>
          </Col>
          <Col md={6} sm={6} xs={6}>
            <div className="d-flex justify-content-end">
              {/* <GoShareAndroid className="share" /> */}
            </div>
          </Col>
          <Col md={12} sm={12} xs={12}>
            <div className="score_box">
              <div className="score">
                <img src={score} className="img-fluid" alt="Score" />
                <div className="grade">
                {/* <span>{totalPercent}%</span> */}
                <span>{marks}%</span>
                  {/* <p>GRADE A</p> */}
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12} sm={12}>
            <div className="round_bg">
              <Container>
                <Row>
                  <Col md={12} sm={12}>
                    <div className="stu_name">
                    {marks > 60 ? <span>You are Excellent,</span> : <span>You need to improve, </span>}
                      <p>{name}</p>
                    </div>
                  </Col>
                  <Col md={12} sm={12}>
                    <Card className="result">
                      <div className="result_card">
                        <Table className="mb-0">
                          <tbody>
                            <tr>
                            <th colSpan={'2'} className="head">{model}</th>
                            </tr>
                            <tr>
                              <td>Total Number of Questions</td>
                              <td>{result.totalQuestions}</td>
                              
                            </tr>
                            <tr>
                              <td>Total marks of Questions</td>
                              <td>{result.totalQuestionsMarks}</td>
                              
                            </tr>
                            <tr>
                              <td>Correct Answers Marks</td>
                              <td>{result.right}</td>
                             
                            </tr>
                            <tr>
                              <td>Incorrect Answers Marks</td>
                              <td>{result.wrong}</td>
                             
                            </tr>
                            <tr>
                              <td>Minus Marks</td>
                              <td>{result.minus}</td>
                             
                            </tr>
                            <tr>
                              <td>Total Marks</td>
                              <td>{result.total_get_marks}</td>
                             
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Card>
                    <Link to="" className="download">
                      <Button size="lg" className="mybutton"  onClick={() => window.print()}>
                        DOWNLOAD PDF <ImFilePdf className="ms-2 fs-5" />
                      </Button>{" "}
                    </Link>{" "}
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Results;

