import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
// import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import { Row, Col, Container, Card } from "react-bootstrap";
import student from "../../assets/svg/ic_attendance.svg";
import money from "../../assets/svg/ic_fees_due.svg";
import quiz from "../../assets/svg/ic_quiz.svg";
import assignment from "../../assets/svg/ic_assignment.svg";
import holiday from "../../assets/svg/ic_holiday.svg";
import time from "../../assets/svg/ic_calendra.svg";
import result from "../../assets/svg/ic_results.svg";
import date from "../../assets/svg/ic_date_sheet.svg";
import doubt from "../../assets/svg/ic_doubts.svg";
import gallery from "../../assets/svg/ic_gallery.svg";
import leave from "../../assets/svg/ic_leave.svg";
import password from "../../assets/svg/ic_password.svg";
import event from "../../assets/svg/ic_event.svg";
import log from "../../assets/svg/ic_logout.svg";
import { useNavigate } from "react-router-dom";
import Holiday from "./Holiday";
import loader from "../../assets/images/giphy.gif";
import userpro from "../../assets/svg/user-profile-avatar-svgrepo-com.svg";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { LiaSchoolSolid } from "react-icons/lia";
import faculty from "../../assets/svg/faculty_profile.svg";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import NotificationsIcon from "@material-ui/icons/Notifications";
import history from "../../assets/svg/transaction-history (1).png";
import notific from "../../assets/svg/notificaiton.png";
import chat from "../../assets/svg/icons8-chat-48.png";
import board from "../../assets/svg/apprentice.png";
import resultt from "../../assets/svg/icons8-report-card-48 (1).png";
import ai from "../../assets/images/ai.png";
import { useLanguage } from "./LanguageContext";
import LanguageDropdown from "../pages/LanguageDropdown";

function Dashboard() {
  const navigate = useNavigate();
  const user_id = localStorage.getItem("user_id");
  const school_id = localStorage.getItem("school_id");
  const class_id = localStorage.getItem("class_id");
  const count = localStorage.getItem("count");
  const token = localStorage.getItem("token");
  const [getuserdata, setdata] = useState([]);
  const [getstudentdata, setStudentData] = useState([]);
  const [getstudentfees, setStudentFee] = useState([]);
  const [getschoolData, setSchoolData] = useState([]);
  const [getclassData, setClassData] = useState([]);
  const [LoggedIn, setLoggedIn] = useState([]);
  const stdnotificationCount = localStorage.getItem("stdnotificationCount");
  const [getnotify, setNotify] = useState(0);
  const [seen, setSeen] = useState(false);
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [notiCount, setNotiCount] = useState([]);
  const [getcount, setCount] = useState(0);
  const isLoading = useRef(false);

  useEffect(() => {
    if (!isLoading.current) {
      loadUser();
      multi_device_session();
    }
    if (user_id == null || token == null) {
      navigate("/");
    }
    const stdnotificationCount = localStorage.getItem("stdnotificationCount");
    if (stdnotificationCount > stdnotificationCount - 1) {
      localStorage.setItem("stdnotificationCount", 0);
    }
  }, [navigate, token, user_id]);

  // const multi_device_session = localStorage.getItem('multi_device_session');

  const languages = [
    { code: "en", name: "English" },
    { code: "hi", name: "Hindi" },
    // Add more languages as needed
  ];

  const { language, changeLanguage } = useLanguage();

  const handleChange = (event) => {
    changeLanguage(event.target.value);
  };

  const loadUser = async () => {
    try {
      isLoading.current = true;
      const url = `${process.env.REACT_APP_API_URL}getStudentProfile`;
      const formData = new FormData();
      formData.append("id", user_id);
      formData.append("school_id", school_id);
      formData.append("class_id", class_id);
      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      setLoading(true);
      const response = await axios(config);
      localStorage.setItem("class_id", response.data.studentData.class_id);
      localStorage.setItem("section_id", response.data.studentData.section_id);
      localStorage.setItem("parent_id", response.data.studentData.parent_id);
      localStorage.setItem("name", response.data.studentData.name);
      setStudentData(response.data.studentData);
      setStudentFee(response.data.studentData);
      setSchoolData(response.data.data.school_details);
      setClassData(response.data.studentData.class_details);
      setCount(response.data.student_lave_count);
      setNotify(response.data.school_student_communication);
      const completeImageUrl = `${response.data.url}/${response.data.studentData.profile_image}`;
      setImage(completeImageUrl);
      isLoading.current = false;
    } catch (error) {
      isLoading.current = false;
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setLoading(false);
    }
  };

  const multi_device_session = async () => {
    const multi_session = localStorage.getItem("multi_device_session");
    console.warn(multi_session);

    try {
      isLoading.current = true;
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}multi_device_session`;
      const formData = new FormData();
      formData.append("id", user_id);
      // formData.append('school_id', school_id);

      const config = {
        method: "post",
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);

      const { data } = response.data;
      // alert(data)
      console.warn(data);
      // alert(data)

      // Check if the response indicates unauthorized access
      // if ( data  != multi_session) {
      //   navigate('/Login');
      //   return;
      // }

      if (data != multi_session) {
        window.location.href = "/";
        return;
      }

      // if(var1 != var2){
      //   logout
      //   }

      isLoading.current = false;
      setLoading(false);
    } catch (error) {
      isLoading.current = false;
      setLoading(false);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const handleNotificationClick1 = () => {
    setSeen(true);
    setNotify(0);
  };

  const handleLogout = () => {
    navigate("/");
    localStorage.clear();
  };

  const handleNotificationClick = () => {
    setCount(0);
    setSeen(true);
  };
  // useEffect(() => {
  //   // This effect will run once the component is mounted
  //   if (window.google && window.google.translate) {
  //     window.googleTranslateElementInit();
  //   }
  // }, []);

  useEffect(() => {
    // Google Translate initialization might need to be handled differently
    // Forcing initialization on component mount
    const initTranslate = () => {
      if (window.google && window.google.translate) {
        window.googleTranslateElementInit();
      }
    };

    // Adding a timeout to ensure the Google Translate script is loaded
    const timer = setTimeout(() => {
      initTranslate();
    }, 1000);

    // Cleanup timeout on component unmount
    return () => clearTimeout(timer);
  }, []);

  const handleLanguageChange = (event) => {
    const language = event.target.value;
    const frame = document.querySelector("iframe.goog-te-banner-frame");
    if (frame) {
      frame.contentWindow.postMessage(
        {
          event: "command",
          command: "translate",
          language: language,
        },
        "*"
      );
    }
  };

  return (
    <div>
      <div className="App">
        {loading ? (
          <div className="img-fluidloder">
            <img src={loader} alt="quiz" />
          </div>
        ) : (
          <div>
            <div className="bg_color_heading Deatails padding-bottom-80">
              <div className="dp1 d-flex align-items-center justify-content-between">
              <h3 className="mb-0 pb-0 text-white">Welcome</h3>

               <div className="d-flex ">
               <div className="languagedropdown" >
                 <LanguageDropdown onChange={handleLanguageChange} />
                 </div>

                <Link to="/Notification" onClick={handleNotificationClick}>
                  <IconButton aria-label="Notifications">
                    <Badge badgeContent={getcount} color="primary">
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                </Link>

                <img className="img1" src={userpro} alt={""} />
                <DropdownButton className="drop1" id="drop" title="">
                  <Link to="/Profile">
                    <Dropdown.ItemText>Profile</Dropdown.ItemText>
                  </Link>
                  <Link to="/Notification">
                    <Dropdown.Item as="button">Notification</Dropdown.Item>
                  </Link>
                  <Link to="/">
                    <Dropdown.Item onClick={handleLogout} as="button">
                      Logout
                    </Dropdown.Item>
                  </Link>
                </DropdownButton>
               </div>

              </div>
            </div>
            <Container>
              <Row className="pb-3 heading_content ">
                <Col lg={12} xs={12}>
                  <Card className="Student_Profile margin-top-minus-60">
                    <div className="profile_1">
                      <div className="stuImg">
                        <img src={image} alt="Student" />
                      </div>
                      <div>
                        <p className="UserName">
                          {getstudentdata && getstudentdata.name}
                        </p>
                        {/* <span className="stuId">Std ID: {getuserdata && getuserdata.id}</span> */}
                        <div className="stuId">
                          STD Email:{" "}
                          {getstudentdata && getstudentdata.student_email}
                        </div>
                      </div>
                    </div>
                    <div className="Student_detail">
                      <div className="details">
                        <p>
                          <LiaSchoolSolid /> Academy Details:
                        </p>
                      </div>
                      <div className="detail_2">
                        <p>
                          {getstudentdata &&
                            `${new Date(
                              getstudentdata.academic_year_start
                            ).getFullYear()}-${new Date(
                              getstudentdata.academic_year_end
                            ).getFullYear()}`}
                        </p>
                        <p>{getschoolData && getschoolData.school_name}</p>
                        <p>{getclassData && getclassData.name}th class</p>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md={12} sm={12}>
                  <div className="round_bg">
                    <Row className="gy-3 gx-0 all_cards">
                      <Col lg={4} md={4} xs={6}>
                        <Link to="/aipage">
                          <Card className="single_card">
                            <div className="dp1">
                              <img src={ai} alt="Student" />
                            </div>
                            <p>{getstudentdata && getstudentdata.attendence}</p>
                            <span>AI Teacher</span>
                          </Card>
                        </Link>
                      </Col>
                      <Col lg={4} md={4} xs={6}>
                        <Link to="/School-Notification">
                          <Card
                            className="single_card"
                            onClick={handleNotificationClick1}
                          >
                            <div className="dp1">
                              <img
                                src={notific}
                                alt="Student"
                                style={{ height: "47px" }}
                              />
                              {!seen && getnotify > 0 && (
                                <span className="position-absolute top-6 translate-middle badge rounded-pill bg-danger">
                                  {getnotify}
                                  <span className="visually-hidden">
                                    unread messages
                                  </span>
                                </span>
                              )}
                            </div>
                            <p>School Notification</p>
                          </Card>
                        </Link>
                      </Col>
                      <Col lg={4} md={4} xs={6}>
                        <Link to="/Faculty">
                          <Card className="single_card">
                            <div className="dp1">
                              <img
                                className="faculty_profile"
                                src={faculty}
                                alt="Student"
                              />
                            </div>
                            <p>Chat With Faculty</p>
                          </Card>
                        </Link>
                      </Col>
                      <Col lg={4} md={4} xs={6}>
                        <Link to="/Groupchat">
                          <Card className="single_card">
                            <div className="dp1">
                              <img src={chat} alt="Student" />
                            </div>
                            <p>Group Chat</p>
                          </Card>
                        </Link>
                      </Col>
                      <Col lg={4} md={4} xs={6}>
                        <Link to="/Doubt">
                          <Card className="single_card">
                            <div className="dp1">
                              <img src={doubt} alt="Student" />
                            </div>
                            <p>Ask Doubts</p>
                          </Card>
                        </Link>
                      </Col>
                      <Col lg={4} md={4} xs={6}>
                        <Link to="/TimeTable">
                          <Card className="single_card">
                            <div className="dp1">
                              <img src={time} alt="Student" />
                            </div>
                            <p>Time Table</p>
                          </Card>
                        </Link>
                      </Col>

                      <Col lg={4} md={4} xs={6}>
                        <Link to="/Attendance">
                          <Card className="single_card">
                            <div className="dp1">
                              <img src={student} alt="Student" />
                            </div>
                            <p>{getstudentdata && getstudentdata.attendence}</p>
                            <span>Attendance</span>
                          </Card>
                        </Link>
                      </Col>
                      <Col lg={4} md={4} xs={6}>
                        <Link to="/Assignment">
                          <Card className="single_card">
                            <div className="dp1">
                              <img src={assignment} alt="Student" />
                            </div>
                            <p>Assignment</p>
                          </Card>
                        </Link>
                      </Col>
                      <Col lg={4} md={4} xs={6}>
                        <Link to="/Fees">
                          <Card className="single_card">
                            <div className="dp1">
                              <img src={money} alt="Student" />
                            </div>
                            <p>{getstudentfees && getstudentfees.dues_fee}</p>
                            <span>Fees Due</span>
                          </Card>
                        </Link>
                      </Col>
                      <Col lg={4} md={4} xs={6}>
                        <Link to="/PayOnline">
                          <Card className="single_card">
                            <div className="dp1">
                              <img
                                src={history}
                                alt="Student"
                                style={{ height: "43px" }}
                              />
                              {/* <span>{getnotify}</span> */}
                            </div>
                            <p>Payment History</p>
                          </Card>
                        </Link>
                      </Col>

                      <Col lg={4} md={4} xs={6}>
                        <Link to="/QuizCourse">
                          <Card className="single_card">
                            <div className="dp1">
                              <img src={quiz} alt="Student" />
                            </div>
                            <p>Play Quiz</p>
                          </Card>
                        </Link>
                      </Col>
                      <Col lg={4} md={4} xs={6}>
                        <Link to="/Application">
                          <Card className="single_card">
                            <div className="dp1">
                              <img src={leave} alt="Student" />
                            </div>
                            <p>Leave Application</p>
                          </Card>
                        </Link>
                      </Col>
                      <Col lg={4} md={4} xs={6}>
                        <Link to="/Holiday">
                          <Card className="single_card">
                            <div className="dp1">
                              <img src={holiday} alt="Student" />
                            </div>
                            <p>School Holiday</p>
                          </Card>
                        </Link>
                      </Col>
                      <Col lg={4} md={4} xs={6}>
                        <Link to="/Events">
                          <Card className="single_card">
                            <div className="dp1">
                              <img src={event} alt="Student" />
                            </div>
                            <p>Events</p>
                          </Card>
                        </Link>
                      </Col>
                      <Col lg={4} md={4} xs={6}>
                        <Link to="/Old_Results">
                          <Card className="single_card">
                            <div className="dp1">
                              <img src={resultt} alt="Student" />
                            </div>
                            <p>Report Card</p>
                          </Card>
                        </Link>
                      </Col>
                      <Col lg={4} md={4} xs={6}>
                        <Link to="/Result">
                          <Card className="single_card">
                            <div className="dp1">
                              <img src={resultt} alt="Student" />
                            </div>
                            <p>Result</p>
                          </Card>
                        </Link>
                      </Col>
                      <Col lg={4} md={4} xs={6}>
                        <Link to="/StudentBoard">
                          <Card className="single_card">
                            <div className="dp1">
                              <img
                                src={board}
                                alt="Student"
                                style={{ height: "43px" }}
                              />
                            </div>
                            <p>Student Board</p>
                          </Card>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
