import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Aipage from './components/pages/Aipage';
import {
  Dashboard,
  Home,
  Login,
  LoginNew,
  Signup,
  Profile,
  Attendance,
  Holiday,
  Fees,
  PayOnline,
  Assignment,
  TimeTable,
  Quiz,
  Result,
  Datesheet,
  Doubt,
  Application,
  Gallery,
  ChangePassword,
  Events,
  Support,
  EventDetail,
  QuizSubject,
  QuizCourse,
  QuizList,
  QuizModel,
  Faculty_profile,
  Notification,
  SNotification,
  Spinner,
  SubmitAssignment,
  StudentBoard,
  TotalAttendance,
  Old_Results,
  Results,
  Chat,
  GroupChat,
  GroupChatting,
} from "./components";
import { LanguageProvider } from './components/pages/LanguageContext';

function App() {

  
  return (
    <LanguageProvider>
    <Router>
      <Routes>
         {/* <Route path="/" element={<Login />} />
          <Route path="/Login" element={<Login />} /> */}
          <Route path="/" element={<LoginNew />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/dashboard" element={<Dashboard /> } />
        <Route path="/aipage" element={<Aipage /> } />
        <Route path="/Profile" element={<Profile /> } />
        <Route path="/Attendance" element={<Attendance /> } />
        <Route path="/Holiday" element={<Holiday /> } />
        <Route path="/Fees" element={<Fees /> } />
        <Route path="/PayOnline" element={<PayOnline /> } />
        <Route path="/Assignment" element={<Assignment /> } />
        <Route path="/TimeTable" element={<TimeTable /> } />
        <Route path="/Quiz" element={<Quiz /> } />
        <Route path="/Result" element={<Result /> } />
        <Route path="/Datesheet" element={<Datesheet /> } />
        <Route path="/Doubt" element={<Doubt /> } />
        <Route path="/Application" element={<Application /> } />
        <Route path="/Gallery" element={<Gallery /> } />
        <Route path="/ChangePassword" element={<ChangePassword />} />
        <Route path="/Events" element={<Events /> } />
        <Route path="/Support" element={<Support />} />
        <Route path="/EventDetail" element={<EventDetail /> } />
        <Route path="/QuizCourse" element={<QuizCourse /> } />
        <Route path="/QuizSubject" element={<QuizSubject /> } />
        <Route path="/QuizList" element={<QuizList /> } />
        <Route path="/QuizModel" element={<QuizModel /> } />
        <Route path="/Notification" element={<Notification /> } />
        <Route path="/School-Notification" element={<SNotification /> } />
        <Route path="/Faculty" element={<Faculty_profile /> } />
        <Route path="/spinner" element={<Spinner />} />
        <Route path="/SubmitAssignment" element={<SubmitAssignment /> } />
        <Route path="/StudentBoard" element={<StudentBoard /> } />
        <Route path="/TotalAttendance" element={<TotalAttendance /> } />
        <Route path="/Old_Results" element={<Old_Results /> } />
        <Route path="/Results" element={<Results /> } />
        <Route path="/Chat" element={<Chat /> } />
        <Route path="/GroupChat" element={<GroupChat /> } />
        <Route path="/GroupChatting" element={<GroupChatting /> } />
        {/* <Route path="/TranslationContext" element={<TranslationContext /> } />
        <Route path="/Constants" element={<Constants /> } /> */}
      </Routes>
    </Router>
    </LanguageProvider>
  );
}

export default App;
